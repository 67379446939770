import { useEffect, useRef, useState } from "react";
import { newDate } from "./date.utils";
interface Time {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
export const useCountDown = () => {
  const [targetDate, setTargetDate] = useState(newDate());
  const isTimeOut = useRef<boolean>(false);

  const calculateTimeLeft = (): Time => {
    const difference = +new Date(targetDate) - +newDate();
    let timeLeft: Time = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState<Time>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      if (Object.values(calculateTimeLeft()).every((n) => n === 0)) {
        clearTimeout(timer);

        isTimeOut.current = true;
      } else isTimeOut.current = false;
    }, 1000);
    return () => clearTimeout(timer);
  });

  return { timeLeft, setTargetDate, isTimeOut };
};

export function simpleCountdown(initialCount = 6) {
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    if (count <= 0) return;

    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [count]);

  return [count, setCount];
}
