import { ILeaveACL } from "@acl/acl.interfaces";
import { commonFieldAcl } from ".";

export const leaveAcl: ILeaveACL = {
	parent: { action: "leaves", resource: "leaves" },
	application: {
		...commonFieldAcl("employee-leave-applications"),
	},
	balance: {
		...commonFieldAcl("employee-leaves"),
	},
	settings: {
		...commonFieldAcl("leave-settings"),
	},
};
