import { apiIns } from "@config/api.config";
import { IQueryParams } from "@interfaces/common.interface";
import { queryStringMapper } from "utils";

export const NotificationService = {
  getAll: async (queryParams?: IQueryParams): Promise<any> =>
    await apiIns.get(`notifications` + queryStringMapper(queryParams)),

  batchRead: async (ids: { ids: string[] }): Promise<any> => await apiIns.put(`notifications/batch-read`, ids),
};
