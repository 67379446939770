import Image from "@components/Image/Image";
import { IEmployeeCard } from "@interfaces/employee.interface";
import { nameMapper, trimString } from "utils";
import { Constant } from "utils/constant";
import { useProfileAvatar } from "utils/hook.utils";

type EmployeeCardProps = {
  index?: any;
  noPadding?: boolean;
  selected?: boolean;
  isDesignationTrim?: boolean;
  employeeData?: IEmployeeCard;
  onClick?: () => void;
  className?: string;
  trimCount?: number;
  nameClassName?: string;
};

const EmployeeCard = ({
  selected = false,
  noPadding = false,
  onClick,
  employeeData,
  className,
  // index = Math.floor(Math.random() * 100) + 1,
  index,
  trimCount = 18,
  nameClassName,
  isDesignationTrim,
}: EmployeeCardProps) => {
  // to maintain random avatar with all profile sequence

  const { path, avatarClass } = useProfileAvatar(employeeData, index);

  return (
    <div
      onClick={onClick && onClick}
      className={`${selected && "bg-secondary-50 sticky top-0 bottom-0"} ${
        !noPadding ? " py-3 px-6" : ""
      } flex items-center rounded-[6px] ${className ? className : ""}`}
    >
      {<Image noBorder src={path} className={`w-10 h-10  rounded overflow-hidden ${avatarClass}`} />}
      <div className={`${nameClassName ? nameClassName : ""} pl-3`}>
        <h4 className="m-0 p-0 font-inter text-sm font-semibold text-left text-dark-600">
          {employeeData?.first_name || employeeData?.last_name
            ? trimString(nameMapper(employeeData), trimCount)
            : Constant.NO_DATA}
        </h4>
        <h5 className="m-0 p-0 font-inter text-[10px] text-left font-normal text-neutral-400">
          {isDesignationTrim && employeeData?.designation
            ? trimString(employeeData?.designation, 15)
            : employeeData?.designation || Constant.NO_DATA}{" "}
        </h5>
        <h5 className="font-inter text-[10px] font-normal text-left text-neutral-400">
          {employeeData?.employee_no || Constant.NO_DATA}
        </h5>
      </div>
    </div>
  );
};

export default EmployeeCard;
