import { ImpulseSpinner, MetroSpinner } from "react-spinners-kit";

interface IPreloaderProps {
  size?: number;
  color?: string;
  frontColor?: string;
  backColor?: string;
  absolutePosition?: boolean;
  className?: string;
}

const Preloader = ({
  size = 30,
  color = "#3899f4",
  absolutePosition = false,
  className,
}: IPreloaderProps) => {
  return (
    <div
      className={`flex items-center justify-center ${className || ""}`}
      style={absolutePosition ? { position: "relative" } : {}}
    >
      <div
        style={
          absolutePosition ? { position: "absolute", zIndex: 1, top: 0 } : {}
        }
      >
        <MetroSpinner size={size} color={color} />
      </div>
    </div>
  );
};

export default Preloader;

export const ButtonLoader = ({
  size = 30,
  color = "#26a11c",

  frontColor = "#fff",
  backColor = "#fff",
  absolutePosition = false,
  className,
}: IPreloaderProps) => (
  <div
    className={`flex items-center justify-center py-2 ${className || ""}`}
    style={absolutePosition ? { position: "relative" } : {}}
  >
    <div
      style={
        absolutePosition ? { position: "absolute", zIndex: 1, top: 0 } : {}
      }
    >
      <ImpulseSpinner
        size={size}
        frontColor={frontColor}
        backColor={backColor}
      />
    </div>
  </div>
);
