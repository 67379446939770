import { IReportCL } from "@acl/acl.interfaces";

export const reportAcl: IReportCL = {
	parent: { action: "reports", resource: "reports" },
	employees: {
		allSummary: {
			action: "allSummary",
			resource: "reports",
		},
		individualDetails: {
			action: "individualDetails",
			resource: "reports",
		},
	},

	attendances: {
		allEmployee: {
			action: "getAttendanceReport",
			resource: "reports",
		},
		individualEmployee: {
			action: "individualAttendance",
			resource: "reports",
		},
		dailySummary: {
			action: "dailyAttendanceReport",
			resource: "reports",
		},
		allSummary: {
			action: "getAttendanceSummaryReport",
			resource: "reports",
		},
	},

	leaves: {
		applicationStatus: {
			action: "getLeaveApplicationStatusReport",
			resource: "reports",
		},
		balance: {
			action: "getLeaveBalanceReport",
			resource: "reports",
		},
	},

	schedules: {
		allEmployee: {
			action: "getSchedulesReport",
			resource: "reports",
		},
	},
};
