import FourZeroFourImg from "@assets/images/FourZeroFour.png";
import Icon from "@components/Icon/Icon";
import { useAuth } from "context/auth";
import { useNavigate } from "react-router-dom";
import { simpleCountdown } from "utils/countDown.hook";

const FourZeroFour = () => {
	const [count, setCount] = simpleCountdown();
	const { isAuthenticated } = useAuth();
	const navigate = useNavigate();

	return (
		<div className="bg-fourZeroOne bg-no-repeat  lg:pt-0 md:pt-32 ">
			<div className=" bg-fourZeroOneRight bg-no-repeat bg-right-bottom ">
				<div className="  lg:px-[130px] lg:flex  justify-between  items-center lg:h-screen">
					<div className="lg:w-6/12 lg:px-0 md:px-[130px] xs:px-5 xs:pt-14 sm:px-14 sm:pt-20">
						<h1 className="font-Inter text-[36px] font-semibold lg:tracking-[5.76px] leading-[130%] text-neutral-800 xs:text-xl sm:text-2xl">
							UH - OH !
						</h1>
						<h1 className="font-Inter text-[20px] font-semibold lg:tracking-[3.2px] leading-[130%] text-neutral-700 mt-[16px] xs:text-sm sm:text-md">
							404 ERROR - PAGE NOT FOUND
						</h1>
						<p className="font-Inter text-[20px] font-semibold  leading-[130%] text-neutral-400 my-[24px] lg:w-[482px] xs:text-sm sm:text-md">
							It looks like you have stumbled upon a broken page.
							Don’t worry we are here to help you get back on
							track.
						</p>
						<div className="flex flex-col space-y-6">
							<button
								onClick={() => {
									isAuthenticated
										? navigate("/dashboard")
										: navigate("/login");
								}}
								className="flex text-primary-500 items-center"
							>
								<Icon name={"keyboard_arrow_left"} />
								<p className="font-Inter text-[16px] font-semibold  leading-[130%] xs:text-sm sm:text-md">
									Go Back ({count.toString()})
								</p>
							</button>
							{/* <button
                onClick={() => {
                  isAuthenticated ? navigate("/dashboard") : navigate("/login");
                }}
                className="flex text-primary-500 items-center"
              >
                <Icon name={"keyboard_arrow_left"} />
                <p className="font-Inter text-[16px] font-semibold  leading-[130%] xs:text-sm sm:text-md">
                  Dashboard
                </p>
              </button> */}
						</div>
					</div>
					<div className="lg:w-6/12  xs:px-10">
						<img
							src={FourZeroFourImg}
							className="!mx-auto lg:mt-0 md:mt-10 "
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FourZeroFour;
