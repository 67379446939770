import BgTop from "@assets/images/NoDataFoundBg.png";
import BgRight from "@assets/images/NoDataFoundBgRight.png";
import banner from "@assets/images/no-internet.png";
import logo from "@assets/images/rysenova-logo-v7_720.png";
import Icon from "@components/Icon/Icon";
import React, { useEffect, useState } from "react";

const saveImagesToLocal = async () => {
  try {
    const images: Record<string, string> = {
      bannerImage: banner,
      logoImage: logo,
      BglogoImage: BgTop,
      BgRightlogoImage: BgRight,
    };
    const offlineImages: Record<string, string> = {};

    for (const key in images) {
      const response = await fetch(images[key]);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        offlineImages[key] = base64data;
      };
    }

    localStorage.setItem("offlineImage", JSON.stringify(offlineImages));
  } catch (error) {
    console.error("Error converting image to base64:", error);
  }
};

interface InternetErrorProps {
  children: React.ReactNode;
}

const InternetError: React.FC<InternetErrorProps> = ({ children }) => {
  const [offline, setOffline] = useState<boolean>(!window.navigator.onLine);
  const [images, setImages] = useState<Record<string, string>>({});

  useEffect(() => {
    const handleOnline = () => {
      setOffline(false);
    };

    const handleOffline = () => {
      setOffline(true);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      await saveImagesToLocal();
    };
    fetchImages();
  }, []);

  useEffect(() => {
    const storedImagesString = localStorage.getItem("offlineImage");
    if (storedImagesString) {
      const storedImages = JSON.parse(storedImagesString);
      setImages(storedImages);
    }
  }, [offline]);

  const handleReload = () => {
    window.location.reload();
  };

  return offline ? (
    <div className="bg-fourZeroOne bg-no-repeat  lg:pt-0 relative h-screen">
      <img
        src={images.BglogoImage}
        alt=""
        className="absolute top-0 left-0 -z-10"
      />
      <img
        src={images.BgRightlogoImage}
        alt=""
        className="absolute bottom-0 right-0 -z-10"
      />
      <div className=" bg-fourZeroOneRight bg-no-repeat bg-right-bottom z-10 my-auto">
        <div className="lg:px-[100px] md:px-[130px] sm:px-14 xs:px-6 lg:pt-[100px] md:pt-[80px] sm:pt-[60px] xs:pt-[40px]">
          <img
            src={images.logoImage}
            alt="logo"
            className="lg:h-10 md:h-8 sm:h-8 xs:h-6 lg:w-[273px] md:w-[200px] sm:w-[180px] xs:w-[160px]"
          />
        </div>
        <div className="  lg:px-[130px] lg:flex  justify-between  items-center lg:mt-[80px]">
          <div className="lg:w-6/12 lg:px-0 md:px-[130px] xs:px-6 xs:pt-14 sm:px-14 ">
            <h1 className="font-Inter text-[36px] font-semibold lg:tracking-[5.76px] leading-[130%] text-neutral-800 sm:text-2xl xs:text-xl">
              UH - OH !
            </h1>
            <p className="font-Inter text-[20px] font-semibold  leading-[130%] text-neutral-400 my-[24px] lg:w-[482px] xs:text-sm sm:text-md">
              LOOKS LIKE YOU’VE LOST YOUR INTERNET CONNECTION.
            </p>
            <p className="font-Inter text-[20px] font-semibold  leading-[130%] text-neutral-400 my-[24px] lg:w-[482px] xs:text-sm sm:text-md">
              CHECK YOUR CONNECTION OR TRY AGAIN.
            </p>
            <button
              className="flex text-primary-500 items-center cursor-pointer"
              onClick={handleReload}
            >
              <Icon name={"refresh"} />
              <p className="font-Inter text-[16px] font-semibold  leading-[130%] xs:text-sm sm:text-md mx-2">
                Reload
              </p>
            </button>
          </div>
          <div className="lg:w-6/12 xs:px-8">
            <img
              src={images.bannerImage}
              className="!mx-auto lg:mt-0 md:mt-10"
              alt="offline banner image"
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

export default InternetError;
