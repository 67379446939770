import { IAclCommonField } from "@acl/acl.interfaces";

export const commonFieldAcl = (module: string): IAclCommonField => {
  return {
    getAll: { action: "getAll", resource: module },
    findOne: { action: "findOne", resource: module },
    create: { action: "store", resource: module },
    update: { action: "update", resource: module },
    remove: { action: "remove", resource: module },
    batchRemove: { action: "batchRemove", resource: module },
    statusUpdate: { action: "activeStatusUpdate", resource: module },
  };
};
