import Logo from "@assets/images/rysenova-logo-v7_720.png";

import { AuthService } from "@services/apis/Auth.service";
import { useSkeleton } from "@services/utils/skeleton.service";
import { ToastService } from "@services/utils/toastr.service";
import { createContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCountDown } from "utils/countDown.hook";
import PasswordReset from "./Components/PasswordReset";
import VerificationLinkBroken from "./Components/VerificationLinkBroken";
interface IUserData {
  expire_at: string;
  token: string;
  user_name: string;
  status: string;
}
interface IEmailVerify {
  token: string | any;
  userData: IUserData | any;
  handleRegenerateEmailLink: Function;
  handleRegeneratePasswordLink: Function;
  timeOut: boolean;
  startSkeleton: boolean;
  type: string | any;
  timeLeft: Object;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  mailRegenerate: boolean;
  setMailRegenerate: (value: boolean) => void;
}

export const EmailVerifyContext = createContext({} as IEmailVerify);

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const type = searchParams.get("type");
  const { timeLeft, setTargetDate, isTimeOut } = useCountDown();
  const [linkBroken, setLinkBroken] = useState<boolean>(false);
  const [mailRegenerate, setMailRegenerate] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserData>();
  const { startSkeleton, stopSkeleton } = useSkeleton();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (token) {
      accountVerification();
    }
  }, [token]);

  const accountVerification = () => {
    if (!token) return;
    setIsLoading(true);
    AuthService.emailVerification(token, type)
      .then((res) => {
        setLinkBroken(false);
        setUserData(res.payload);
        if (res?.payload?.expire_at) {
          setTargetDate(res.payload.expire_at);
        }
      })
      .catch((err) => {
        ToastService.error(err.message);
        setLinkBroken(true);
      })
      .finally(() => {
        stopSkeleton();
        setIsLoading(false);
      });
  };
  const handleRegeneratePasswordLink = (responseToken: string) => {
    if (!responseToken) return;
    setIsLoading(true);
    AuthService.regeneratePasswordSetupLink(responseToken, type)
      .then((res) => {
        setLinkBroken(false);
        setUserData(res.payload);
        setTargetDate(res.payload.expire_at);
        isTimeOut.current = false;
      })
      .catch((err) => {
        ToastService.error(err.message);
        setLinkBroken(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRegenerateEmailLink = () => {
    if (!token) return;
    setIsLoading(true);
    AuthService.regenerateEmailVerificationLink(token, type)
      .then((res) => {
        setMailRegenerate(true);
        setLinkBroken(false);
        setUserData(res.payload);
        setTargetDate(res.payload.expire_at);
      })
      .catch((err) => {
        ToastService.error(err.message);
        setLinkBroken(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <EmailVerifyContext.Provider
      value={{
        token,
        userData,
        handleRegenerateEmailLink,
        timeOut: isTimeOut.current,
        handleRegeneratePasswordLink,
        mailRegenerate,
        setMailRegenerate,
        startSkeleton,
        type,
        timeLeft,
        isLoading,
        setIsLoading,
      }}
    >
      <div className="relative bg-cover bg-center">
        <div className="h-[100vh] overflow-hidden">
          <div className="lg:pl-10  mt-10 ">
            <img src={Logo} alt="" className="w-auto h-10" />
          </div>
          {linkBroken ? <VerificationLinkBroken /> : <PasswordReset />}
        </div>

        <div
          className={`absolute top-10 left-10 -z-[1]  shadow-2xl `}
          style={{
            backgroundColor: "rgba(56, 153, 244, 0.22)",
            height: "318px",
            width: "320px",
            borderRadius: "400px",
            boxShadow: "0px 0px 0px 0px rgba(151, 71, 255, 0.09)",
            filter: "blur(75px)",
          }}
        />
        <div
          className={`absolute right-0 -bottom-10 -z-[1]  shadow-2xl  `}
          style={{
            backgroundColor: "rgba(56, 153, 244, 0.22)",
            height: "318px",
            width: "320px",
            borderRadius: "400px",
            boxShadow: "0px 0px 0px 0px rgba(56, 153, 244, 0.22)",
            filter: "blur(75px)",
          }}
        />
      </div>
    </EmailVerifyContext.Provider>
  );
};

export default EmailVerification;
