import { ReactNode, createContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
type AlertProps = {
  children: ReactNode;
  show: boolean;
  setShow: Function;
  showBlur?: boolean;
  backDrop?: boolean;
};
type AlertPartProps = {
  children: ReactNode;
};
type contextProps = {
  show: boolean;
  setShow: Function;
};
const AlertContext = createContext({} as contextProps);

const Alert = ({
  children,
  show,
  setShow,
  showBlur,
  backDrop = false,
}: AlertProps) => {
  const wrapperRef: any = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (backDrop) {
      const handleClickOutside = (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setShow(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [wrapperRef]);

  useEffect(() => {
    if (backDrop) {
      const handleClickOutside = (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setShow(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [wrapperRef]);

  useEffect(() => {
    const html = document.querySelector("html");

    if (html) {
      if (show && html) {
        html.style.overflowY = "hidden";

        const focusableElements =
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

        const Alert: any = document.querySelector("#Alert"); // select the Alert by it's id

        const firstFocusableElement =
          Alert.querySelectorAll(focusableElements)[0]; // get first element to be focused inside Alert

        const focusableContent = Alert.querySelectorAll(focusableElements);

        const lastFocusableElement =
          focusableContent[focusableContent?.length - 1]; // get last element to be focused inside Alert

        document.addEventListener("keydown", function (e) {
          if (e.keyCode === 27) {
            setShow(false);
          }

          const isTabPressed = e.key === "Tab" || e.keyCode === 9;

          if (!isTabPressed) {
            return;
          }

          if (e.shiftKey) {
            // if shift key pressed for shift + tab combination
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus(); // add focus for the last focusable element
              e.preventDefault();
            }
          } else {
            // if tab key is pressed
            if (document.activeElement === lastFocusableElement) {
              // if focused has reached to last focusable element then focus first focusable element after pressing tab
              firstFocusableElement.focus(); // add focus for the first focusable element
              e.preventDefault();
            }
          }
        });

        firstFocusableElement.focus();
      } else {
        html.style.overflowY = "visible";
      }
    }
  }, [show]);

  return (
    <>
      <AlertContext.Provider value={{ show, setShow }}>
        {show && typeof document !== "undefined"
          ? ReactDOM.createPortal(
              <div
                className={`fixed top-0 left-0 z-50 flex h-screen w-screen sm:w-full xs:w-full items-center justify-center ${
                  showBlur && "backdrop-blur-sm"
                }`}
                aria-labelledby="header-3a content-3a"
                tabIndex={-1}
                role="dialog"
              >
                <div className="fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-dark-900 opacity-40" />
                <div
                  ref={wrapperRef}
                  className="animate-bounce-in relative z-50 flex max-h-[90vh] sm:w-96 xs:w-80 lg:w-138 max-w-xl  flex-col gap-6 overflow-hidden rounded-xl bg-white  p-3 text-slate-500 shadow-xl shadow-slate-700/10"
                  id="Alert"
                  role="document"
                >
                  <header
                    id="header-3a"
                    className="flex justify-end items-center"
                  >
                    <button
                      className="border-none outline-none"
                      onClick={() => setShow(false)}
                    >
                      <span className="material-icons-outlined text-text-secondary">
                        cancel
                      </span>
                    </button>
                  </header>
                  {children}
                </div>
              </div>,
              document.body
            )
          : null}
      </AlertContext.Provider>
    </>
  );
};

const AlertBody = ({ children }: AlertPartProps) => (
  <div className="flex-1 overflow-auto">{children}</div>
);
const AlertFooter = ({ children }: AlertPartProps) => (
  <footer className="flex-1 overflow-auto">{children}</footer>
);

Alert.Body = AlertBody;
Alert.Footer = AlertFooter;
export default Alert;
