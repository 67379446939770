import { IHolidayACL } from "@acl/acl.interfaces";
import { commonFieldAcl } from ".";

export const holidayAcl: IHolidayACL = {
  holiday: {
    ...commonFieldAcl("holidays"),
    batchInsert: {
      action: "batchInsert",
      resource: "holidays",
    },
    batchUpdate: {
      action: "batchUpdate",
      resource: "holidays",
    },
  },
  group: {
    ...commonFieldAcl("holiday-groups"),
    batchAssign: { action: "batchAssign", resource: "holiday-groups" },
  },
};
