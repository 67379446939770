import { integrationSettingsAcl } from "@acl/modules/setting.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import { DASHBOARD_PAYROLL, EMPLOYEE_SALARIES, SALARY, SALARY_COMPONENTS, SALARY_STRUCTURE } from "./path-name.route";

export const payrollRoutes: IRouteList[] = [
  // for dashboard payroll
  {
    path: DASHBOARD_PAYROLL + "/",
    component: lazy(() => import("@pages/DashboardPayroll/DashboardSalary/DashboardSalary")),
    // meta: integrationSettingsAcl.apis.parent,
    children: [
      {
        path: EMPLOYEE_SALARIES,
        component: lazy(() => import("@pages/DashboardPayroll/DashboardSalary/Tab/EmploySalaries/EmploySalaries")),
        // meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
    ],
  },

  {
    path: EMPLOYEE_SALARIES + "/details",
    component: lazy(
      () => import("@pages/DashboardPayroll/DashboardSalary/Tab/EmploySalaries/Components/EmploySalaryDetails")
    ),
    // meta: integrationSettingsAcl.apis.parent,
  },
  {
    path: EMPLOYEE_SALARIES + "/assign",
    component: lazy(() => import("@pages/DashboardPayroll/DashboardSalary/Tab/EmploySalaries/Components/SalaryAssign")),
    // meta: integrationSettingsAcl.apis.parent,
  },

  // for settings payroll
  {
    path: SALARY,
    component: lazy(() => import("@pages/Settings/Payroll/Salary/Salary")),
    meta: integrationSettingsAcl.apis.parent,
    children: [
      {
        path: SALARY_COMPONENTS,
        component: lazy(() => import("@pages/Settings/Payroll/Salary/SalaryComponents/SalaryComponents")),
        meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
      {
        path: SALARY_STRUCTURE,
        component: lazy(() => import("@pages/Settings/Payroll/Salary/SalaryStructure/SalaryStructure")),
        meta: integrationSettingsAcl.apis.apiSecretKeys.getAll,
      },
    ],
  },
];
