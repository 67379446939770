import {
	IFacilitiesSettingsACL,
	IGeneralSettingsACL,
	IHRMSettingsACL,
	IIntegrationSettingsACL,
	ISettingACL,
} from "@acl/acl.interfaces";
import { commonFieldAcl } from ".";

export const settingsAcl: ISettingACL = {
	accountSetting: {
		action: "account-settings",
		resource: "account-settings",
	},
	systemSettings: {
		parent: { action: "system-settings", resource: "system-settings" },
		address: { action: "storeAddress", resource: "global-settings" },
		createAccountSettings: {
			action: "createOrUpdate",
			resource: "employee-creation-settings",
		},
		general: { action: "companyInfo", resource: "global-settings" },
		systemInfo: { action: "systemConfig", resource: "global-settings" },
	},

	notification: { action: "system-settings", resource: "system-settings" },

	hrm: {
		parent: { action: "hrm-settings", resource: "hrm-settings" },
		team: {
			...commonFieldAcl("teams"),
			assignLineManager: {
				action: "teams-assign-line-manager",
				resource: "teams-assign-line-manager",
			},
		},
		group: {
			...commonFieldAcl("groups"),
			revokeMember: {
				action: "revokeMember",
				resource: "groups",
			},
			assignMember: {
				action: "assignMember",
				resource: "groups",
			},
			assignLineManager: {
				action: "assignLineManager",
				resource: "groups",
			},
		},
		shift: { ...commonFieldAcl("shifts") },
		locations: { ...commonFieldAcl("office-locations") },
		session: { ...commonFieldAcl("session") },
	},
	leave: {
		parent: { action: "leave-settings", resource: "leave-settings" },
		settings: { ...commonFieldAcl("leave-settings") },
		policy: { ...commonFieldAcl("approval-policies") },
		leaveType: { ...commonFieldAcl("leave-types") },
	},
	employment: {
		parent: { action: "employment", resource: "employment" },
		type: { ...commonFieldAcl("employment-types") },
		division: { ...commonFieldAcl("divisions") },
		department: { ...commonFieldAcl("departments") },
		grade: { ...commonFieldAcl("grades") },
		designation: { ...commonFieldAcl("designations") },
		education: { ...commonFieldAcl("educations") },
	},
	acl: {
		parent: { action: "acl", resource: "acl" },
		user: {
			...commonFieldAcl("users"),
			roleAssignAndRevoke: {
				action: "assignAndRevoke",
				resource: "user-roles",
			},
			permissionAssignAndRevoke: {
				action: "assignAndRevoke",
				resource: "user-permissions",
			},
			resendEmail: {
				action: "accountVerificationMailResend",
				resource: "users",
			},
		},
		role: {
			...commonFieldAcl("roles"),
			rolePermissionAssign: {
				action: "assignAndRevoke",
				resource: "role-permissions",
			},
		},
	},
	bankInfo: {
		parent: { action: "bank-info", resource: "bank-info" },
		bank: { ...commonFieldAcl("banks") },
		branch: { ...commonFieldAcl("branches") },
		digitalBank: { ...commonFieldAcl("digital-bankings") },
	},
	facilities: {
		parent: {
			action: "setting-facilities",
			resource: "setting-facilities",
		},
		holidays: { ...commonFieldAcl("holidays") },
		holidayGroup: { ...commonFieldAcl("holiday-groups") },
		assetType: { ...commonFieldAcl("asset-types") },
	},
	integrations: {
		parent: {
			action: "setting-integrations",
			resource: "setting-integrations",
		},
		attendanceLocations: { ...commonFieldAcl("attendance-locations") },
		attendanceDevices: { ...commonFieldAcl("attendance-devices") },
		apiSecretKeys: { ...commonFieldAcl("api-secret-keys") },
	},
};

export const generalSettingsAcl: IGeneralSettingsACL = {
	parent: { action: "general", resource: "general" },
	accountSetting: {
		action: "account-settings",
		resource: "account-settings",
	},
	systemSettings: {
		parent: { action: "system-settings", resource: "system-settings" },
		address: { action: "storeAddress", resource: "global-settings" },
		createAccountSettings: {
			action: "createOrUpdate",
			resource: "employee-creation-settings",
		},
		general: { action: "companyInfo", resource: "global-settings" },
		systemInfo: { action: "systemConfig", resource: "global-settings" },
	},
	notification: { action: "notifications", resource: "notifications" },
};

export const hrmSettingsAcl: IHRMSettingsACL = {
	parent: { action: "hrm-settings", resource: "hrm-settings" },
	hrmManagement: {
		parent: { action: "hrm-management", resource: "hrm-management" },
		team: {
			...commonFieldAcl("teams"),
			assignLineManager: {
				action: "teams-assign-line-manager",
				resource: "teams-assign-line-manager",
			},
		},
		group: {
			...commonFieldAcl("groups"),
			revokeMember: {
				action: "revokeMember",
				resource: "groups",
			},
			assignMember: {
				action: "assignMember",
				resource: "groups",
			},
			assignLineManager: {
				action: "assignLineManager",
				resource: "groups",
			},
		},
		shift: { ...commonFieldAcl("shifts") },
		locations: { ...commonFieldAcl("office-locations") },
		session: {
			...commonFieldAcl("financial-sessions"),
			currentStatusUpdate: {
				action: "currentStatusUpdate",
				resource: "financial-sessions",
			},
			closedStatusUpdate: {
				action: "closedStatusUpdate",
				resource: "financial-sessions",
			},
		},
	},
	leave: {
		parent: { action: "leaves-settings", resource: "leaves-settings" },
		settings: { ...commonFieldAcl("leave-settings") },
		policy: { ...commonFieldAcl("approval-policies") },
		leaveType: { ...commonFieldAcl("leave-types") },
	},
	approval: {
		parent: { action: "approval-settings", resource: "approval-settings" },
		approvals: { ...commonFieldAcl("approval-policies") },
	},
	employment: {
		parent: { action: "employment", resource: "employment" },
		type: { ...commonFieldAcl("employment-types") },
		division: { ...commonFieldAcl("divisions") },
		department: { ...commonFieldAcl("departments") },
		grade: { ...commonFieldAcl("grades") },
		designation: { ...commonFieldAcl("designations") },
		education: { ...commonFieldAcl("educations") },
	},
	acl: {
		parent: { action: "acl", resource: "acl" },
		user: {
			...commonFieldAcl("users"),
			roleAssignAndRevoke: {
				action: "assignAndRevoke",
				resource: "user-roles",
			},
			permissionAssignAndRevoke: {
				action: "assignAndRevoke",
				resource: "user-permissions",
			},
			resendEmail: {
				action: "accountVerificationMailResend",
				resource: "users",
			},
		},
		role: {
			...commonFieldAcl("roles"),
			rolePermissionAssign: {
				action: "assignAndRevoke",
				resource: "role-permissions",
			},
		},
	},
};

export const facilitiesSettingsAcl: IFacilitiesSettingsACL = {
	parent: { action: "facilities-settings", resource: "facilities-settings" },
	bankInfo: {
		parent: { action: "bank-info", resource: "bank-info" },
		bank: { ...commonFieldAcl("banks") },
		branch: { ...commonFieldAcl("branches") },
		digitalBank: { ...commonFieldAcl("digital-bankings") },
	},
	facilities: {
		parent: {
			action: "setting-facilities",
			resource: "setting-facilities",
		},
		holidays: { ...commonFieldAcl("holidays") },
		holidayGroup: { ...commonFieldAcl("holiday-groups") },
		assetType: { ...commonFieldAcl("asset-types") },
	},
};

export const integrationSettingsAcl: IIntegrationSettingsACL = {
	parent: {
		action: "integrations",
		resource: "integrations",
	},
	attendanceDevice: {
		parent: {
			action: "attendance-devices",
			resource: "attendance-devices",
		},
		attendanceLocations: { ...commonFieldAcl("attendance-locations") },
		attendanceDevices: { ...commonFieldAcl("attendance-devices") },
	},
	apis: {
		parent: {
			action: "apis",
			resource: "apis",
		},
		apiSecretKeys: { ...commonFieldAcl("api-secret-keys") },
	},
};
