import SuccessCard from "@components/Card/SuccessCard";
import { LocalStorageService } from "@services/utils/localsStorage.service";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import EmailSent from "@assets/images/emailSent.png";
const EmailSentSuccess = () => {
  const navigate = useNavigate();

  const [timer, setTimer] = useState("");

  useEffect(() => {
    const expir = LocalStorageService.get("expireAt");
    const type = LocalStorageService.get("type");
    if (new Date(expir) < new Date()) {
      LocalStorageService.clear();
      navigate("/reset-password");
      return;
    }
  }, []);

  useEffect(() => {
    onCountDown();
  }, []);

  const onCountDown = () => {
    const otpExp = LocalStorageService.get("expireAt") || new Date();
    if (!otpExp) return;

    // Set the date we're counting down to
    const countDownDate = new Date(otpExp).getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setTimer(`${minutes}:${seconds}`);

      if (distance < 0) {
        clearInterval(x);
        LocalStorageService.clear();
        navigate("/reset-password");
      }
    }, 1000);
  };
  return (
    <>
      <h1 className="text-primary-600 font-bold text-[28px] px-6 font-inter mt-6 ml-4">
        Rysenova
      </h1>
      <div className="flex justify-center items-center w-full max-h-screen mt-[5%]">
        <SuccessCard
          img={EmailSent}
          status="An email was sent"
          message="Successfully!"
        >
          <p className="font-inter text-base font-medium text-neutral-500">
            Please check your{" "}
            <span className="text-primary-500 underline font-semibold cursor-pointer underline-offset-2">
              Email
            </span>{" "}
            and set the password
          </p>
          <p className="font-inter text-base font-medium text-neutral-500 mt-3">
            Did not receive a verification email ? You can resend after{" "}
            <span className="text-primary-500 underline font-semibold underline-offset-4">
              {timer}
            </span>
          </p>
        </SuccessCard>
      </div>
    </>
  );
};

export default EmailSentSuccess;
