import { defaultEmployeeAcl, lineManagerAcl } from "@acl/modules/employee.data";
import { leaveAcl } from "@acl/modules/leave.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
	EMPLOYEE_LEAVE,
	LEAVE_APPLICATION,
	LEAVE_MANAGEMENT,
	OWN_APPLICATION,
	OWN_BALANCE,
} from "./path-name.route";

export const leaveRoutes: IRouteList[] = [
	{
		path: LEAVE_MANAGEMENT + "/*",
		component: lazy(() => import("@pages/LeaveManagement/LeaveManagement")),
		meta: {
			action: leaveAcl.parent.action,
			resource: leaveAcl.parent.resource,
		},
		children: [
			{
				path: LEAVE_APPLICATION,
				component: lazy(
					() =>
						import(
							"@pages/LeaveManagement/LeaveApplication/LeaveApplication"
						)
				),
				meta: [
					{
						action: leaveAcl.application.getAll.action,
						resource: leaveAcl.application.getAll.resource,
					},
					{
						action: lineManagerAcl.action,
						resource: lineManagerAcl.resource,
					},
				],
			},
			{
				path: EMPLOYEE_LEAVE,
				component: lazy(
					() =>
						import(
							"@pages/LeaveManagement/EmployeeLeaves/EmployeeLeaves"
						)
				),
				meta: [
					{
						action: leaveAcl.balance.getAll.action,
						resource: leaveAcl.balance.getAll.resource,
					},
					{
						action: lineManagerAcl.action,
						resource: lineManagerAcl.resource,
					},
				],
			},
			// {
			// 	path: PEER_APPLICATION,
			// 	component: lazy(
			// 		() =>
			// 			import(
			// 				"@pages/LeaveManagement/PeerApplication/PeerApplication"
			// 			)
			// 	),
			// 	meta: {
			// 		action: lineManagerAcl.action,
			// 		resource: lineManagerAcl.resource,
			// 	},
			// },
			{
				path: OWN_APPLICATION,
				component: lazy(
					() =>
						import(
							"@pages/LeaveManagement/OwnApplication/OwnApplication"
						)
				),
				meta: {
					action: defaultEmployeeAcl.action,
					resource: defaultEmployeeAcl.resource,
				},
			},
			{
				path: OWN_BALANCE,
				component: lazy(
					() =>
						import("@pages/LeaveManagement/OwnBalance/LeaveBalance")
				),
				meta: {
					action: defaultEmployeeAcl.action,
					resource: defaultEmployeeAcl.resource,
				},
			},
		],
	},
];
