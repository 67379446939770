import { IAclMeta } from "@interfaces/common.interface";
import GuestLayout from "@layouts/GuestLayout";
import Organization from "@pages/Authentication/Organization/Organization";
import { FC, lazy } from "react";
import { accountRoutes } from "./account.route";
import { aclRoutes } from "./acl.route";
import { approvalSettingsRoutes } from "./approval.route";
import { assetRoutes } from "./asset.route";
import { attendanceRoutes } from "./attendance.route";
import { bankRoutes } from "./bank.route";
import { dashboardRoute } from "./dashboard.route";
import { employeeRoutes } from "./employee.route";
import { employmentRoutes } from "./employment.route";
import { facilitiesRoutes } from "./facilities";
import { formBuildersRoutes } from "./form-builder.route";
import { HrManagementRoutes } from "./hr-management.route";
import { integrationsRoutes } from "./integrations.route";
import { leaveSettingsRoutes } from "./leave-settings.route";
import { leaveRoutes } from "./leave.route";
import { organizationCalenderRoute } from "./organizationCalenderRoute";
import { ATTENDANCE_SETTINGS, DASHBOARD, DEVICES, HOME, LOGIN, NOTIFICATIONS, RESETPASSWORD } from "./path-name.route";
import { payrollRoutes } from "./payroll.route";
import { reportRoutes } from "./report.route";
import { ScheduleRoutes } from "./shedule.route";
import { systemRoutes } from "./system.route";

const Login = lazy(() => import("@pages/Authentication/Login"));
const ResetPassword = lazy(() => import("@pages/Authentication/ResetPassword"));
const Dashboard = lazy(() => import("@pages/Dashboard/Dashboard"));

const NotFound = lazy(() => import("@pages/Authentication/NotFound"));
// const Profile = lazy(() => import("@pages/Profile/Profile"));

const AttendanceDevices = lazy(
  () => import("@pages/Settings/Integrations/Attendance/Tabs/AttendanceDevice/AttendanceDevices")
);

const Notifications = lazy(() => import("@pages/Notifications/NotificationScreen"));

export interface IRouteList {
  path: string;
  component?: FC;
  params?: { [key: string]: string };
  children?: IRouteList[];
  redirect?: string;
  isIndex?: boolean;
  meta?: IAclMeta | IAclMeta[];
}

// NOTE:: don't try to implement another layer routing without permission for auth route list
export const authRouteList: IRouteList[] = [
  {
    path: HOME,
    component: GuestLayout,
    children: [
      {
        path: HOME,
        component: Organization,
      },
      {
        path: LOGIN,
        component: Login,
      },
      {
        path: RESETPASSWORD,
        component: ResetPassword,
      },
    ],
  },
];

export const privateRoutes: IRouteList[] = [
  {
    path: "/",
    redirect: DASHBOARD,
  },
  {
    path: DASHBOARD,
    component: Dashboard,
  },
  {
    path: "*",
    component: NotFound,
  },
  {
    path: ATTENDANCE_SETTINGS + "/" + DEVICES,
    component: AttendanceDevices,
  },
  {
    path: NOTIFICATIONS,
    component: Notifications,
  },
];

export const privateRouteList = [
  ...privateRoutes,
  ...employeeRoutes,
  ...employmentRoutes,
  ...leaveRoutes,
  ...leaveSettingsRoutes,
  ...assetRoutes,
  ...attendanceRoutes,
  ...aclRoutes,
  ...bankRoutes,
  ...facilitiesRoutes,
  // ...ProfileRoutes,
  ...systemRoutes,
  ...accountRoutes,
  // ...NotificationsRoutes,
  ...ScheduleRoutes,
  ...HrManagementRoutes,
  ...reportRoutes,
  ...dashboardRoute,
  ...integrationsRoutes,
  ...approvalSettingsRoutes,
  ...formBuildersRoutes,
  ...organizationCalenderRoute,
  ...payrollRoutes,
];
