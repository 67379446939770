import UnAuth from "@assets/images/unauth.svg";
import VerificationBg from "@assets/images/verificationBg.svg";
import Button from "@components/Button/Button";
import { useTranslation } from "react-i18next";

const Unauthorized = () => {
  const { t } = useTranslation();
  return (
    <div
      className="flex justify-center items-center w-screen h-screen bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${VerificationBg})` }}
    >
      <div className="w-auto h-auto flex flex-col justify-center items-center">
        <img src={UnAuth} alt="" className="w-100 h-100" />
        <h4 className="mt-5 font-inter text-base font-normal text-dark-400">
          {t("Attention")}
        </h4>
        <h2 className="mt-1.5 mb-3 font-inter text-5xl sm:text-2xl xs:text-xl font-bold text-dark-400">
          {t("Unauthorized")}
        </h2>
        <h5 className="mt-1.5 mb-3 font-inter text-base font-normal text-dark-400">
          {t("You are not authorized for selected action")}
        </h5>
        <div className="flex flex-col justify-center items-center mt-3">
          <Button round="md" variant="fill">
            {t("Log In")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
