import FourZeroFiveImg from "@assets/images/FourZeroFive.png";
import Icon from "@components/Icon/Icon";

const FourZeroFive = () => {
  return (
    <div className="bg-fourZeroOne bg-no-repeat  lg:pt-0 md:pt-32 ">
      <div className=" bg-fourZeroOneRight bg-no-repeat bg-right-bottom ">
        <div className="  lg:px-[130px] lg:flex  justify-between  items-center lg:h-screen">
          <div className="lg:w-6/12 lg:px-0 md:px-[130px] xs:px-5 xs:pt-14 sm:px-14 sm:pt-20">
            <h1 className="font-Inter text-[36px] font-semibold lg:tracking-[5.76px] leading-[130%] text-neutral-800 xs:text-xl sm:text-2xl">
              UH - OH !
            </h1>
            <h1 className="font-Inter text-[20px] font-semibold lg:tracking-[3.2px] leading-[130%] text-neutral-700 mt-[16px] xs:text-sm sm:text-md">
              405 ERROR - METHOD NOT ALLOWED
            </h1>
            <p className="font-Inter text-[20px] font-semibold  leading-[130%] text-neutral-400 my-[24px] lg:w-[482px] xs:text-sm sm:text-md">
              Lorem ipsum dolor sit amet consectetur. In gravida sit fermentum
              augue non. Aenean et interdum sit morbi massa vitae odio sed.
            </p>
            <button className="flex text-primary-500 items-center">
              <Icon name={"keyboard_arrow_left"} />
              <p className="font-Inter text-[16px] font-semibold  leading-[130%] xs:text-sm sm:text-md">
                Go Back
              </p>
            </button>
          </div>
          <div className="lg:w-6/12  xs:px-10">
            <img
              src={FourZeroFiveImg}
              className="!mx-auto lg:mt-0 mt-10 "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourZeroFive;
