import Logo from "@assets/images/rysenova-logo-v7_720.png";
import { useAuth } from "context/auth";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { HOME, LOGIN } from "router/path-name.route";
import { useImageSwitchCase } from "utils";

const GuestLayout: React.FC = () => {
  const imageUrl = useImageSwitchCase();
  const { hasOrg } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (hasOrg) {
      navigate(LOGIN);
    } else {
      navigate(HOME);
    }
  }, [hasOrg]);
  return (
    <div>
      <div className=" lg:pl-10  mt-10 sm:ml-6 xs:ml-6">
        <img src={Logo} alt="" className="w-auto h-10" />
      </div>
      <div className="bg-cover bg-center relative">
        <div
          className={`absolute lg:ms-5 lg:-mt-52 md:-mt-28  shadow-2xl shrink-0`}
          style={{
            backgroundColor: "rgba(151, 71, 255, 0.09)",
            height: "318px",
            width: "320px",
            borderRadius: "400px",
            boxShadow: "0px 0px 0px 0px rgba(151, 71, 255, 0.09)",
            filter: "blur(75px)",
          }}
        ></div>
        <div
          className={`absolute lg:ms-32 md:ms-20 lg:-mt-32 md:-mt-20  opacity-50 shadow-2xl shrink-0 `}
          style={{
            backgroundColor: "rgba(56, 153, 244, 0.22)",
            height: "318px",
            width: "320px",
            borderRadius: "400px",
            boxShadow: "0px 0px 0px 0px rgba(56, 153, 244, 0.22)",
            filter: "blur(75px)",
          }}
        ></div>
        <div className="sm:flex sm:flex-col lg:flex-row mt-[7%]">
          <div
            // style={{
            //   background: "linear-gradient(152.56deg, #0671D4 0%, #51ABFE 101.29%)",
            // }}
            className="lg:w-6/12 sm:w-full sm:h-[400px]  lg:max-h-screen  justify-center items-center h-[100vh] md:block hidden"
          >
            <img src={imageUrl} alt="" className=" flex mx-auto" />
          </div>
          <div className="lg:w-6/12 sm:w-full xs:mt-6">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestLayout;
