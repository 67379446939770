
    // Hide the root div initially
      const rootDiv = document.getElementById("root");
      rootDiv.style.display = "none";

      // Show the root div once the script is loaded
      document.addEventListener("DOMContentLoaded", () => {
        rootDiv.style.display = "block";
        // Remove the spinner
        const spinnerDiv = document.getElementById("spinner-container");
        spinnerDiv.remove();
      });
    