import EmailVerify from "@assets/images/emailVerifyImg.png";
import LinkBroken from "@assets/images/verificationLinkBroken.png";
import Button from "@components/Button/Button";
import Input from "@components/Input/Input";
import EmailVerifySkeleton from "@components/Skeleton/EmailVerifySkeleton";
import { yupResolver } from "@hookform/resolvers/yup";
import { IResetPasswordPayload } from "@interfaces/auth.interface";
import { AuthService } from "@services/apis/Auth.service";
import { ButtonLoader } from "@services/utils/preloader.service";
import { ToastService } from "@services/utils/toastr.service";
import { t } from "i18next";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { EmailVerifyContext } from "../EmailVerification";

const schema = yup.object().shape({
  newPassword: yup.string().min(8, t("Password must be a minimum of 8 characters")).required(t("Password is Required")),
  confirmPassword: yup
    .string()
    .min(8, t("Confirm Password must be a minimum of 8 characters"))
    .oneOf([yup.ref("newPassword")], t("Passwords do not match"))
    .required(t("Confirm Password is required")),
});

const PasswordReset = () => {
  const {
    startSkeleton,
    userData,
    timeOut,
    handleRegeneratePasswordLink,
    handleRegenerateEmailLink,
    token,
    timeLeft,
    mailRegenerate,
    isLoading,
    setIsLoading,
  } = useContext(EmailVerifyContext);
  const navigate = useNavigate();

  const defaultValue = {
    newPassword: "",
    confirmPassword: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const onSubmit: any = (data: IResetPasswordPayload) => {
    if (!userData?.token) return;
    setIsLoading(true);
    AuthService.updatePassword(userData?.token, userData?.type, data)
      .then(() => {
        navigate("/password-update-success");
      })
      .catch((error) => {
        ToastService.error(error.message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="h-[80vh] flex justify-center items-center ">
      <div className="basis-6/12 flex justify-center items-center ">
        <img src={userData?.status === "NEW_LINK_REQUEST" ? LinkBroken : EmailVerify} alt="" className="" />
      </div>
      <div className="basis-6/12 ">
        {startSkeleton ? (
          <EmailVerifySkeleton />
        ) : (
          <div className="w-full">
            {userData?.status === "NEW_LINK_REQUEST" ? (
              <div className="w-full">
                <h3 className="mb-4 font-inter text-[28px] text-text-primary font-semibold">
                  Sorry ! Your verification link has been expired.
                </h3>
                <h5 className="mb-4 font-inter text-base text-text-primary font-medium">Hi, {userData?.user_name}</h5>
                <p className="mb-6 font-inter text-base text-text-primary w-2/3">
                  Your email can not be verified as you entered an expired link. You can send request for another link.
                </p>
                <Button disable={isLoading} onClick={() => handleRegenerateEmailLink(token)} color="primary" round="md">
                  {isLoading ? <ButtonLoader /> : "Request for New Link"}
                </Button>
              </div>
            ) : userData?.status === "NEW_LINK_REQUESTED" && mailRegenerate ? (
              <div className="w-full">
                <h5 className="mb-4 font-inter text-base text-text-primary font-medium">Hi, {userData?.use_name}</h5>
                <p className="mb-6 font-inter text-base text-text-primary w-2/3">
                  Please check your email, your request for new link has been sent successfully. Or, go back to Login.
                </p>
                <Link to={"/login"}>
                  <Button color="primary" round="md">
                    Login into your account
                  </Button>
                </Link>
              </div>
            ) : userData?.status === "VERIFIED_AND_UPDATED" ? (
              <>
                <h3 className="mb-3 font-inter text-[26px] text-text-primary font-semibold">
                  Your email has already been verified.
                </h3>
                <h5 className="mb-3 font-inter text-base text-text-primary font-medium">Hi, {userData?.user_name}</h5>
                <p className="mb-6 font-inter text-base text-text-primary w-2/3">
                  Your email has already been verified.Login into your account.
                </p>
                <Link to={"/login"}>
                  <Button color="primary" round="md">
                    Login into your account
                  </Button>
                </Link>
              </>
            ) : timeOut && userData?.status !== "NEW_LINK_REQUEST" ? (
              <div>
                <h3 className="mb-3 font-inter text-[26px] text-text-primary font-semibold">
                  Your email has already been verified.
                </h3>
                <h5 className="mb-3 font-inter text-base text-text-primary font-medium">Hi, {userData?.user_name}</h5>
                <p className="mb-1 font-inter text-base text-text-primary w-2/3">
                  Your time validation for set password has expired.{" "}
                  <span
                    onClick={() => !isLoading && handleRegeneratePasswordLink(userData?.token)}
                    className="text-primary-500 cursor-pointer"
                  >
                    Try again
                  </span>
                </p>
                {isLoading && (
                  <div className="lds-facebook">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
              </div>
            ) : (
              userData?.status === "USER_PASSWORD_SET_TOKEN" && (
                <>
                  <h3 className="mb-3 font-inter text-[26px] text-text-primary font-semibold">
                    Your email has already been verified.
                  </h3>
                  <h5 className="mb-3 font-inter text-base text-text-primary font-medium">Hi, {userData?.user_name}</h5>
                  <p className="mb-6 font-inter text-base text-text-primary w-2/3">
                    Your email has already been verified. Set your password within
                    <span className="mx-1">
                      {timeLeft.minutes}:{timeLeft.seconds}
                    </span>
                    minutes
                  </p>
                  <form className="w-128" onSubmit={handleSubmit(onSubmit)}>
                    <Input
                      classNames="mb-8"
                      label="add_new_password"
                      placeholder="enter_new_password"
                      errorText={errors?.newPassword?.message}
                      registerProperty={register("newPassword")}
                      type="password"
                    />
                    <Input
                      label="confirm_new_password"
                      placeholder="confirm_new_password"
                      errorText={errors?.confirmPassword?.message}
                      registerProperty={register("confirmPassword")}
                      type="password"
                    />
                    <div className="mt-9">
                      <Button
                        className="px-6 py-3 rounded-[8px] flex justify-center"
                        type="submit"
                        width="full"
                        color="primary"
                        variant="fill"
                        disable={isLoading}
                      >
                        {isLoading ? <ButtonLoader /> : t("Save")}
                      </Button>
                    </div>
                  </form>
                </>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
