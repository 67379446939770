import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import { ar } from "./Arabic/index.ar";
import { bn } from "./Bengali/index.bn";
import { en } from "./English/index.en";

i18n
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, // make it true for debugging with console and others
    fallbackLng: "en",
    resources: {
      en: {
        translation: en,
      },
      bn: {
        translation: bn,
      },
      ar: {
        translation: ar,
      },
    },
  });

export default i18n;
