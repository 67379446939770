import { lazy } from "react";
import { IRouteList } from "./list.route";
import { ASSETS, ASSETS_MANAGEMENT } from "./path-name.route";
import { assetAcl } from "@acl/modules/asset.data";

export const assetRoutes: IRouteList[] = [
  {
    path: ASSETS_MANAGEMENT + ASSETS,
    component: lazy(() => import("@pages/AssetManagement/Assets/Assets")),
    meta: {
      action: assetAcl.getAll.action,
      resource: assetAcl.getAll.resource,
    },
  },
];
