// ** Imports createContext function
import { ReactNode, createContext } from "react";

// ** Imports createContextualCan function
import { createContextualCan } from "@casl/react";
import ability from "@acl/ability";
import { IActionResource } from "./acl.interfaces";
import { isArray } from "utils";

// ** Create Context
export const AbilityContext = createContext<any>({});

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer);

export const AbilityProvider = (props: any) => {
	return (
		<AbilityContext.Provider value={ability}>
			<>{props.children}</>
		</AbilityContext.Provider>
	);
};
interface IHasPermitted {
	permission: IActionResource | IActionResource[];
	children: ReactNode;
}

export const HasPermitted = ({ permission, children }: IHasPermitted) => {
	if (!permission) return <></>;

	if (isArray(permission)) {
		return permission?.some((item: IActionResource) =>
			ability.can(item?.action, item?.resource)
		) ? (
			children
		) : (
			<></>
		);
	}

	return (
		<Can I={permission.action} a={permission.resource}>
			{children}
		</Can>
	);
};
