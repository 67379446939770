import ArabicTranslation from "./ar.json";
import ArEmployeeTranslation from "./employee.ar.json";
import ArLabel from "./label.ar.json";
import ArAccountSetting from "./settings/account-setting.ar.json";
import ArSystemSetting from "./settings/system-setting.ar.json";
import ArSidebarTranslation from "./sidebar.ar.json";
import placeholder from "./placeholder.ar.json";
import attendanceModule from "./attendance.ar.json";
import leaveModule from "./leave.ar.json";
import scheduleModule from "./schedule.ar.json";
import assetsModule from "./assets.ar.json";
import bankInfo from "./settings/bank-info-ar.json";
import facilitiesModule from "./settings/facilities.ar.json";
import aclModule from "./settings/acl.ar.json";
import responseMessage from "./response-message.ar.json";
import validationMessage from "./validation.ar.json";
import reportsModule from "./reports.ar.json";
import dashboardTranslations from "./dashboard.ar.json";
import hrManagement from "./settings/hr-management.ar.json";

export const ar = {
  ...ArabicTranslation,
  ...ArSidebarTranslation,
  ...ArEmployeeTranslation,
  ...ArAccountSetting,
  ...ArLabel,
  ...ArSystemSetting,
  ...placeholder,
  ...attendanceModule,
  ...leaveModule,
  ...scheduleModule,
  ...assetsModule,
  ...bankInfo,
  ...facilitiesModule,
  ...aclModule,
  ...responseMessage,
  ...validationMessage,
  ...reportsModule,
  ...dashboardTranslations,
  ...hrManagement,
};
