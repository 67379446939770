import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
	BANK,
	BANK_MANAGEMENT,
	BRANCH,
	DIGITAL_BANKING,
} from "./path-name.route";
import { facilitiesSettingsAcl } from "@acl/modules/setting.data";

export const bankRoutes: IRouteList[] = [
	{
		path: BANK_MANAGEMENT + "/*",
		component: lazy(
			() => import("@pages/Settings/BankManagement/BankManagement")
		),
		meta: {
			action: facilitiesSettingsAcl.bankInfo.parent.action,
			resource: facilitiesSettingsAcl.bankInfo.parent.resource,
		},
		children: [
			{
				path: BANK,
				component: lazy(
					() =>
						import("@pages/Settings/BankManagement/Tabs/Bank/Bank")
				),
				meta: {
					action: facilitiesSettingsAcl.bankInfo.bank.getAll.action,
					resource:
						facilitiesSettingsAcl.bankInfo.bank.getAll.resource,
				},
			},
			{
				path: BRANCH,
				component: lazy(
					() =>
						import(
							"@pages/Settings/BankManagement/Tabs/Branch/Branch"
						)
				),
				meta: {
					action: facilitiesSettingsAcl.bankInfo.branch.getAll.action,
					resource:
						facilitiesSettingsAcl.bankInfo.branch.getAll.resource,
				},
			},
			{
				path: DIGITAL_BANKING,
				component: lazy(
					() =>
						import(
							"@pages/Settings/BankManagement/Tabs/DigitalBanking/DigitalBanking"
						)
				),
				meta: {
					action: facilitiesSettingsAcl.bankInfo.digitalBank.getAll
						.action,
					resource:
						facilitiesSettingsAcl.bankInfo.digitalBank.getAll
							.resource,
				},
			},
		],
	},
];
