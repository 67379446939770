import LinkBroken from "@assets/images/verificationLinkBroken.png";
import Button from "@components/Button/Button";
import EmailVerifySkeleton from "@components/Skeleton/EmailVerifySkeleton";
import { updateEmailVerifyContext } from "@pages/Authentication/UpdateEmail";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { EmailVerifyContext } from "../EmailVerification";

interface Props {
  contextType?: string;
}

const VerificationLinkBroken = ({ contextType }: Props) => {
  const { startSkeleton } = useContext(
    contextType === "verifyUpdateEmail" ? updateEmailVerifyContext : EmailVerifyContext
  );

  return (
    <div className="h-[80vh] flex justify-center items-center ">
      <div className="basis-4/12 flex justify-center items-center ">
        <img src={LinkBroken} alt="" className="" />
      </div>
      {startSkeleton ? (
        <EmailVerifySkeleton />
      ) : (
        <div className="basis-6/12 ">
          <div className="w-full">
            <h3 className="mb-4 font-inter text-[28px] text-text-primary font-semibold">
              Your token is invalid and can not be verified.
            </h3>

            <Link to={"/login"}>
              <Button color="primary" round="md">
                Login into your account
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerificationLinkBroken;
