const EmailVerifySkeleton = () => {
  return (
    <div>
      <div className="w-2/3 h-10 mb-6 rounded skeleton" />
      <div className="w-48 h-6 mb-6 skeleton rounded" />
      <div>
        <div className="w-2/3 h-3 my-1.5 skeleton rounded" />
        <div className="w-2/4 h-3 my-1.5 skeleton rounded" />
        <div className="w-1/3 h-3 my-1.5 skeleton rounded" />
      </div>
      <div className="w-52 mt-6 h-8 skeleton rounded" />
    </div>
  );
};

export default EmailVerifySkeleton;
