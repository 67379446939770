import Button from "@components/Button/Button";
import { Link } from "react-router-dom";

const SuccessUpdateEmailVerify = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col items-center space-y-4">
        <p className="text-3xl text-center text-primary-600 font-semibold">Your email is updated successfully</p>

        <Link className="text-center mt-4" to={"/login"}>
          <Button color="primary" round="md">
            Login into your account
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SuccessUpdateEmailVerify;
