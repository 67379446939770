import { Ability } from "@casl/ability";
import { LocalStorageService } from "@services/utils/localsStorage.service";

const permissions = LocalStorageService.get("permissions") || [];
// permissions = processingMenuPermission(permissions);
// console.log("🚀 ~ file: ability.ts:9 ~ permissions:", permissions);
// const permissions = dummyPermission;
// const permissions = superAdmin;
// console.log(processingMenuPermission(permissions));

export default new Ability(permissions);
