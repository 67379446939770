import { navListAclData } from "@acl/modules/menu.data";
import ScheduleSvg from "@assets/menuIcons/access_time.svg";
import LeaveSvg from "@assets/menuIcons/calendar_today.svg";
import ReportSvg from "@assets/menuIcons/equalizer.svg";
import AttendanceSvg from "@assets/menuIcons/how_to_reg.svg";
import EmployeeSvg from "@assets/menuIcons/person.svg";
import AssetSvg from "@assets/menuIcons/phonelink.svg";
import DashboardSvg from "@assets/menuIcons/space_dashboard.svg";
import { ENV } from "@config/ENV.config";
import {
  ACCOUNT_SETTINGS,
  ACL,
  APIS,
  APPROVAL_SETTINGS,
  ASSETS,
  ASSETS_MANAGEMENT,
  ATTENDANCE,
  ATTENDANCES_DEV,
  BANK_MANAGEMENT,
  DASHBOARD,
  DASHBOARD_PAYROLL,
  EMPLOYEES,
  EMPLOYMENTS,
  FACILITIES,
  FORM_BUILDER_MODULE,
  HR,
  LEAVE_MANAGEMENT,
  LEAVE_SETTINGS,
  REPORTS,
  SALARY,
  SCHEDULE,
  SYSTEM_SETTINGS,
} from "router/path-name.route";
import { defaultPagination } from "utils/hook.utils";

interface ACL {
  action: string;
  resource: string;
}

interface INavElement {
  id: string;
  label: string;
  children?: INavChildren[];
  link?: string;
  meta?: ACL;
  icon?: string;
  menuIcon?: any;
}

export interface INavLink {
  general: INavElement[];
  settings: INavElement[];
  menuIcon?: any;
}

export interface INavChildren {
  id: string;
  label: string;
  icon: string;
  link: string;
  meta?: ACL;
  menuIcon?: any;
  children?: any;
}

export const navLinks: INavLink = {
  general: [
    {
      id: "R1",
      label: "analytics",
      meta: {
        action: navListAclData.analysis.parent.action,
        resource: navListAclData.analysis.parent.resource,
      },
      children: [
        {
          id: "R1C1",
          label: "dashboard",
          link: DASHBOARD,
          menuIcon: DashboardSvg,
          icon: "space_dashboard",
          meta: {
            action: navListAclData.analysis.dashboard.action,
            resource: navListAclData.analysis.dashboard.resource,
          },
        },
        {
          id: "R1C2",
          label: "reports",
          link: REPORTS,
          menuIcon: ReportSvg,
          icon: "equalizer",
          meta: {
            action: navListAclData.analysis.report.action,
            resource: navListAclData.analysis.report.resource,
          },
        },
      ],
    },
    {
      id: "R2",
      label: "hr_management",
      meta: {
        action: navListAclData.hrm.parent.action,
        resource: navListAclData.hrm.parent.resource,
      },
      children: [
        {
          id: "R2C1",
          icon: "person",
          label: "employees",
          link: EMPLOYEES,
          menuIcon: EmployeeSvg,
          meta: {
            action: navListAclData.hrm.employees.action,
            resource: navListAclData.hrm.employees.resource,
          },
        },

        {
          id: "R2C3",
          icon: "how_to_reg",
          label: "attendance",
          link: ATTENDANCE,
          menuIcon: AttendanceSvg,
          meta: {
            action: navListAclData.hrm.attendances.action,
            resource: navListAclData.hrm.attendances.resource,
          },
        },
        {
          id: "R2C4",
          icon: "calendar_today",
          label: "leave",
          link: LEAVE_MANAGEMENT,
          menuIcon: LeaveSvg,
          meta: {
            action: navListAclData.hrm.leave.action,
            resource: navListAclData.hrm.leave.resource,
          },
        },
        {
          id: "R2C5",
          icon: "access_time",
          label: "schedules",
          link: SCHEDULE,
          menuIcon: ScheduleSvg,
          meta: {
            action: navListAclData.hrm.schedules.action,
            resource: navListAclData.hrm.schedules.resource,
          },
        },
      ],
    },
    {
      id: "R3",
      label: "facilities",
      meta: {
        action: navListAclData.facilities.parent.action,
        resource: navListAclData.facilities.parent.resource,
      },
      children: [
        {
          id: "R3C1",
          label: "assets",
          link: ASSETS_MANAGEMENT + ASSETS + defaultPagination,
          menuIcon: AssetSvg,
          icon: "phonelink",
          meta: {
            action: navListAclData.facilities.assets.action,
            resource: navListAclData.facilities.assets.resource,
          },
        },
      ],
    },

    ...(ENV.env !== "production"
      ? [
          {
            id: "R4",
            label: "PAYROLL",
            meta: {
              action: navListAclData.facilities.parent.action,
              resource: navListAclData.facilities.parent.resource,
            },
            children: [
              {
                id: "R4C1",
                label: "Salary",
                link: DASHBOARD_PAYROLL,
                icon: "attach_money",
                meta: {
                  action: navListAclData.facilities.assets.action,
                  resource: navListAclData.facilities.assets.resource,
                },
              },
              // Uncomment if needed
              // {
              //   id: "R4C2",
              //   label: "Payroll Hub",
              //   link: PAYROLL_HUB,
              //   icon: "attach_money",
              //   meta: {
              //     action: navListAclData.facilities.assets.action,
              //     resource: navListAclData.facilities.assets.resource,
              //   },
              // },
            ],
          },
        ]
      : []),
  ],
  settings: [
    {
      id: "R1",
      label: "analytics",
      meta: {
        action: navListAclData.analysis.dashboard.action,
        resource: navListAclData.analysis.dashboard.resource,
      },
      children: [
        {
          id: "R1C1",
          label: "dashboard",
          link: DASHBOARD,
          menuIcon: DashboardSvg,
          icon: "space_dashboard",
          meta: {
            action: navListAclData.analysis.dashboard.action,
            resource: navListAclData.analysis.dashboard.resource,
          },
        },
      ],
    },
    {
      id: "R2",
      label: "general_setting",
      meta: navListAclData.generalSettings.parent,
      children: [
        {
          id: "R2C1",
          label: "profile",
          link: ACCOUNT_SETTINGS,
          icon: "person",
          meta: navListAclData.generalSettings.accountSetting,
        },
        {
          id: "R2C2",
          label: "system",
          link: SYSTEM_SETTINGS,
          icon: "work_outline",
          meta: navListAclData.generalSettings.systemSettings.parent,
        },
        // {
        // 	id: "R2C2",
        // 	label: "Policy",
        // 	link: SYSTEM_SETTINGS,
        // 	icon: "work_outline",
        // 	meta: navListAclData.generalSettings.systemSettings.parent,
        // },
        // {
        // 	id: "R2C3",
        // 	label: "notifications",
        // 	link: NOTIFICATIONS,
        // 	icon: "notifications",
        // 	meta: {
        // 		action: navListAclData.settings.notifications.action,
        // 		resource:
        // 			navListAclData.settings.notifications.resource,
        // 	},
        // },
        // {
        //   id: "R2C3",
        //   label: "Form Builder",
        //   link: FORM_BUILDER_MODULE,
        //   icon: "group",
        //   // meta: navListAclData.generalSettings.systemSettings.parent,
        // },
        ...(ENV.env !== "production"
          ? [
              {
                id: "R2C3",
                label: "Form Builder",
                link: FORM_BUILDER_MODULE,
                icon: "group",
                // meta: navListAclData.generalSettings.systemSettings.parent,
              },
            ]
          : []),
      ],
    },

    {
      id: "R3",
      label: "hr_management",
      meta: navListAclData.hrmSettings.parent,
      children: [
        {
          id: "R3C1",
          label: "hr_management",
          link: HR,
          icon: "person",
          meta: navListAclData.hrmSettings.hrmManagement.parent,
        },
        {
          id: "R3C2",
          label: "employment",
          link: EMPLOYMENTS,
          icon: "people",
          meta: navListAclData.hrmSettings.employment.parent,
        },
        {
          id: "R3C3",
          label: "approvals",
          link: APPROVAL_SETTINGS,
          icon: "approval",
          meta: navListAclData.hrmSettings.leave.parent,
        },
        {
          id: "R3C4",
          label: "leaves",
          link: LEAVE_SETTINGS,
          icon: "calendar_today",
          meta: navListAclData.hrmSettings.leave.parent,
        },
        {
          id: "R2C5",
          label: "acl",
          link: ACL,
          icon: "admin_panel_settings",
          meta: navListAclData.hrmSettings.acl.parent,
        },
      ],
    },
    {
      id: "R4",
      label: "facilities",
      meta: navListAclData.facilitiesSettings.parent,
      children: [
        {
          id: "R4C1",
          label: "bank_info",
          link: BANK_MANAGEMENT,
          icon: "account_balance",
          meta: navListAclData.facilitiesSettings.bankInfo.parent,
        },
        {
          id: "R4C2",
          label: "facilities",
          link: FACILITIES,
          icon: "phonelink",
          meta: navListAclData.facilitiesSettings.facilities.parent,
        },
      ],
    },
    {
      id: "R5",
      label: "integrations",
      meta: navListAclData.integrationSettings.parent,
      children: [
        {
          id: "R5C1",
          label: "att_devices",
          link: ATTENDANCES_DEV,
          icon: "integration_instructions",
          meta: navListAclData.integrationSettings.attendanceDevice.parent,
        },
        {
          id: "R5C1",
          label: "apis",
          link: APIS,
          icon: "api",
          meta: navListAclData.integrationSettings.apis.parent,
        },
      ],
    },

    ...(ENV.env !== "production"
      ? [
          {
            id: "R6",
            label: "PAYROLl",
            meta: navListAclData.integrationSettings.parent,

            children: [
              {
                id: "R6C1",
                label: "Salary",
                link: SALARY,
                icon: "attach_money",
                meta: navListAclData.integrationSettings.attendanceDevice.parent,
              },
            ],
          },
        ]
      : []),
  ],
};
