import { lazy } from "react";
import {
	DEPARTMENT,
	DESIGNATION,
	DIVISION,
	EDUCATIONAL_QUALIFICATION,
	EMPLOYMENTS,
	EMPLOYMENT_TYPES,
	GRADES,
} from "./path-name.route";
import { hrmSettingsAcl } from "@acl/modules/setting.data";
import { IRouteList } from "./list.route";

export const employmentRoutes: IRouteList[] = [
	{
		path: EMPLOYMENTS + "/*",
		component: lazy(
			() => import("@pages/Settings/Employments/Employments")
		),
		meta: {
			action: hrmSettingsAcl.employment.parent.action,
			resource: hrmSettingsAcl.employment.parent.resource,
		},
		children: [
			{
				path: EMPLOYMENT_TYPES,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Employments/Tabs/EmploymentTypes/EmploymentTypes"
						)
				),
				meta: {
					action: hrmSettingsAcl.employment.type.getAll.action,
					resource: hrmSettingsAcl.employment.type.getAll.resource,
				},
			},
			{
				path: DIVISION,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Employments/Tabs/Divisions/Divisions"
						)
				),

				meta: {
					action: hrmSettingsAcl.employment.division.getAll.action,
					resource:
						hrmSettingsAcl.employment.division.getAll.resource,
				},
			},
			{
				path: DEPARTMENT,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Employments/Tabs/Departments/Departments"
						)
				),
				meta: {
					action: hrmSettingsAcl.employment.department.getAll.action,
					resource:
						hrmSettingsAcl.employment.department.getAll.resource,
				},
			},
			{
				path: DESIGNATION,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Employments/Tabs/Designations/Designations"
						)
				),
				meta: {
					action: hrmSettingsAcl.employment.designation.getAll.action,
					resource:
						hrmSettingsAcl.employment.designation.getAll.resource,
				},
			},
			{
				path: GRADES,
				component: lazy(
					() =>
						import("@pages/Settings/Employments/Tabs/Grades/Grades")
				),
				meta: {
					action: hrmSettingsAcl.employment.grade.getAll.action,
					resource: hrmSettingsAcl.employment.grade.getAll.resource,
				},
			},
			{
				path: EDUCATIONAL_QUALIFICATION,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Employments/Tabs/Educations/Educations"
						)
				),
				meta: {
					action: hrmSettingsAcl.employment.education.getAll.action,
					resource:
						hrmSettingsAcl.employment.education.getAll.resource,
				},
			},
		],
	},
];
