import { t } from "i18next";
import { toast } from "react-toastify";

export const ToastService = {
  success: (message: string): any => toast.success(t(message) || "Success!"),
  error: (message: string): any =>
    toast.error(t(message) || "Something went wrong!"),
  info: (message: string): any => toast.info(message || "Information!"),
  warning: (message: string): any => toast.warning(message || "Warning!"),
};
