import { IScheduleACL } from "@acl/acl.interfaces";
import { commonFieldAcl } from ".";

export const scheduleAcl: IScheduleACL = {
	parent: { action: "hrm", resource: "hrm" },
	schedules: {
		...commonFieldAcl("schedules"),
		assignEmployees: { action: "assignEmployees", resource: "schedules" },
		assignShifts: { action: "assignShifts", resource: "schedules" },
		getCalendarView: { action: "getCalendarView", resource: "schedules" },
		repeatSchedule: { action: "repeatSchedule", resource: "schedules" },
	},
	roster: {
		...commonFieldAcl("rosters"),
	},
	fixedHour: {
		...commonFieldAcl("fixed-hours"),
	},
};
