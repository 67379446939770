const HeaderSKeleton = ({ show }: { show: boolean }) => {
  return (
    <div
      className={`flex justify-between items-center h-16 mx-10 bg-white ${
        show ? "" : "hidden"
      }`}
    >
      <div className="flex">
        <div className=" w-[254px] flex gap-3 items-center">
          <div className="skeleton w-9 h-8 rounded" />
          <div className="skeleton w-24 h-5 rounded" />
        </div>
        <div className="ml-20 lg:flex xs:hidden sm:hidden flex gap-4 items-center ">
          <div className="skeleton w-6 h-6 rounded-full" />
          <div className="skeleton w-44 h-6 rounded" />
        </div>
      </div>
      <div className="header-right relative flex items-center">
        <div className="mr-10 flex items-end relative text-neutral-400">
          <div className="skeleton w-6 h-6 rounded" />
        </div>

        <div className="flex items-center gap-2">
          <div className="grid justify-items-end">
            <div className="skeleton w-36 h-4 rounded mb-3" />
            <div className="skeleton w-28 h-2 rounded" />
          </div>
          <div>
            <div className="flex items-center">
              <div className="skeleton w-10 h-10 rounded" />
              <div className="ml-2 skeleton w-4 h-3 rounded" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSKeleton;
