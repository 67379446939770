import { IAclCommonField, IActionResource, IEmployeeModuleACL } from "@acl/acl.interfaces";

export const commonField = (module: string): IAclCommonField => {
  return {
    getAll: { action: "getAll", resource: module },
    findOne: { action: "findOne", resource: module },
    create: { action: "store", resource: module },
    update: { action: "update", resource: module },
    remove: { action: "remove", resource: module },
    batchRemove: { action: "batchRemove", resource: module },
    statusUpdate: { action: "activeStatusUpdate", resource: module },
  };
};
export const defaultEmployeeAcl: IActionResource = {
  action: "employee",
  resource: "employee",
};
export const lineManagerAcl: IActionResource = {
  action: "line-manager",
  resource: "line-manager",
};

export const employeeACL: IEmployeeModuleACL = {
  employee: {
    general: {
      ...commonField("employees"),
      form: { action: "employee-form", resource: "employee-form" },
      details: {
        action: "employee-details",
        resource: "employee-details",
      },
    },
    personal: {
      ...commonField("employee-personal-info"),
      form: {
        action: "employee-personal",
        resource: "employee-personal",
      },
    },
    // family: commonField("employee-family-info"),
    employment: {
      ...commonField("employee-employment-info"),
      form: {
        action: "employee-employment",
        resource: "employee-employment",
      },
    },
    bank: {
      ...commonField("employee-bank-info"),
      form: {
        action: "employee-bank",
        resource: "employee-bank",
      },
    },
    family: {
      ...commonField("employee-family-info"),
      form: {
        action: "employee-family",
        resource: "employee-family",
      },
    },
    education: {
      ...commonField("employee-educational-info"),
      form: {
        action: "employee-education",
        resource: "employee-education",
      },
    },
    training: {
      ...commonField("employee-training-info"),
      form: {
        action: "employee-training",
        resource: "employee-training",
      },
    },
    history: {
      ...commonField("employee-history"),
      form: {
        action: "employee-history",
        resource: "employee-history",
      },
    },
    serviceBook: {
      ...commonField("employee-service-books"),
      form: {
        action: "employee-service-book",
        resource: "employee-service-book",
      },
    },
    actions: {
      ...commonField("employee-disciplinary-actions"),
      form: {
        action: "employee-action",
        resource: "employee-action",
      },
    },
    achievement: {
      ...commonField("employee-achievement"),
      form: {
        action: "employee-achievement",
        resource: "employee-achievement",
      },
    },
    address: {
      ...commonField("employee-address"),
      form: {
        action: "employee-address",
        resource: "employee-address",
      },
    },
    document: {
      ...commonField("employee-documents"),
      form: {
        action: "employee-document",
        resource: "employee-document",
      },
    },
  },
  peer: defaultEmployeeAcl,
  team: defaultEmployeeAcl,
  group: defaultEmployeeAcl,
};
