import { lazy } from "react";
import {
	ACCOUNT_PASSWORD,
	ACCOUNT_SECURITY_PRIVACY,
	ACCOUNT_SETTINGS,
	GENERAL_SETTING,
} from "./path-name.route";
import { IRouteList } from "./list.route";
import { generalSettingsAcl } from "@acl/modules/setting.data";

const AccountSettings = lazy(
	() => import("@pages/Settings/AccountSettings/AccountSettings")
);

export const accountRoutes: IRouteList[] = [
	{
		path: ACCOUNT_SETTINGS + "/*",
		component: AccountSettings,
		meta: {
			action: generalSettingsAcl.accountSetting.action,
			resource: generalSettingsAcl.accountSetting.resource,
		},
		children: [
			{
				path: GENERAL_SETTING,
				component: lazy(
					() =>
						import(
							"@pages/Settings/AccountSettings/Tabs/GeneralSettings"
						)
					// () => import("@pages/Profile/Profile")
				),
			},
			{
				path: ACCOUNT_PASSWORD,
				component: lazy(
					() =>
						import(
							"@pages/Settings/AccountSettings/Tabs/ResetPassword"
						)
				),
			},
			{
				path: ACCOUNT_SECURITY_PRIVACY,
				component: lazy(
					() =>
						import(
							"@pages/Settings/AccountSettings/Tabs/SecurityAndPrivacy"
						)
				),
			},
		],
	},
];
