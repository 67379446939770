export const HOME: string = "/";
export const LOGIN: string = "/login";
export const RESETPASSWORD: string = "/reset-password";
export const NEW_PASSWORD: string = "/new-password";
export const AUTH: string = "/";
// dashboard
export const DASHBOARD: string = "/dashboard";
export const EMPLOYEE: string = "employee";
export const ADMIN: string = "admin";
export const Application_Status: string = "/application-status";
export const Balance_REPORT: string = "/balance";

// organization calender

export const ORGANIZATION_CALENDER: string = "/calendar";

// onboard
export const ONBOARD: string = "/onboard";
export const BASIC_INFO: string = "/basic-info";
export const STEP: string = "/step";

// REPORTS
export const REPORTS: string = "/reports";
// tabs
export const TEAM_REPORT: string = "teams";
// pages

export const ALL_SUMMARY: string = "/all-summary";
export const INDIVIDUAL_SUMMARY: string = "/individual-summary";
export const ATTENDANCE_REPORT: string = "/attendance";
export const INDIVIDUAL_ATTENDANCE_REPORTS: string = "/individual-attendance";
export const EMPLOYEE_SUMMARY: string = "/employee-summary";
export const ALL_EMPLOYEE_ATTENDANCE_SUMMARY: string = "employee-summary";
export const ALL_EMPLOYEE_ATTENDANCE_STATISTICS: string = "employee-statistics";
export const EMPLOYEE_STATISTICS: string = "/employee-statistics";
export const EMPLOYEE_LOG: string = "/attendance-record";

//--- Report leave
export const LEAVE: string = "/leave";
export const APPLICATION_STATUS: string = "/application-status";
export const SUMMARY: string = "summary";
export const STATISTICS: string = "statistics";
export const BALANCE_REPORT: string = "/balance";

export const SCHEDULES_OVERVIEW: string = "/schedules-overview";

export const SETTINGS: string = "/settings";

// employment
export const EMPLOYMENTS = SETTINGS + "/employments";
export const DIVISION: string = "divisions";
export const DEPARTMENT: string = "departments";
export const GRADES: string = "grades";
export const DESIGNATION: string = "designations";
export const EDUCATIONAL_QUALIFICATION: string = "educational-qualification";
export const EMPLOYMENT_TYPES: string = "employment-types";

export const ROLE: string = "roles";
export const USER_ROLE: string = "user-role";
export const USERS: string = "users";
export const USER_DETAILS: string = USERS + "/:userId";
export const USER_PERMISSION: string = USERS + "/assign-permission";
export const ROLE_PERMISSION: string = ROLE + "/permission";

export const EMPLOYEES: string = "/employees";
export const EMPLOYEE_LIST: string = "employee-list";
export const EMPLOYEE_DETAILS: string = "employee-details";
export const PEERS: string = "peers";
export const TEAMS: string = "teams";
export const GROUPS: string = "groups";

export const EMPLOYEE_FORM: string = EMPLOYEES + "/form/*";
export const GENERAL: string = "/general";
export const DOCUMENTS: string = "/documents";
export const PERSONAL: string = "/personal";
export const SERVICE_BOOKS: string = "/service-books";

// hr -management
export const HR = SETTINGS + "/hr";
export const LEAVE_TYPES: string = "types";
export const SHIFT_MANAGEMENT: string = "shift-management";
export const OFFICE_LOCATIONS: string = "office-locations";
export const SESSION: string = "session";
export const ASSETS: string = "/assets";
export const ASSET: string = "assets";

// Schedule
export const SCHEDULE: string = "/schedules";
export const ROSTERS: string = "rosters";
export const FIXED_HOURS: string = "fixed-hours";
export const EMPLOYEE_SCHEDULE: string = "employee-schedules";

export const LIST_VIEW: string = "list-view";
export const ROSTER_FORM: string = SCHEDULE + "/form/*";
export const SCHEDULE_DETAILS: string = SCHEDULE + "/details";

export const ROSTER_SETTINGS: string = "roster-settings";
export const ROSTERS_OVERRIDE: string = "rosters-override";
export const SHIFTS: string = "shifts";

export const ASSIGN_ROSTER: string = "assign-roster";

export const ATTENDANCE: string = "/attendances";
export const ATTENDANCE_LIST: string = "employee-summary";
export const ATTENDANCE_CHECK_IN: string = "check-in";
export const ATTENDANCE_SUMMARY: string = "summary";
export const ATTENDANCE_RECORD: string = "employee-log";
export const PENDING_REQUEST: string = "pending-request";
export const ATTENDANCE_SETTINGS: string = "settings";

// system-setting
export const SYSTEM_SETTINGS: string = SETTINGS + "/system";
export const SYSTEM_CONTACT: string = "contact";
export const SYSTEM_ADDRESS: string = "address";
export const SYSTEM_INFO: string = "info";
export const CUSTOMER_ACCOUNTS: string = "customer-accounts";
export const EMPLOYMENTS_SETTING: string = "employment";

export const ACCOUNT_SETTINGS: string = SETTINGS + "/profile";
export const GENERAL_SETTING: string = "general";
export const ACCOUNT_PASSWORD: string = "reset-password";
export const ACCOUNT_SECURITY_PRIVACY: string = "security-privacy";

// documents
export const DOCUMENTS_MODULE: string = SETTINGS + "/documents";
export const COMPANY_DOCUMENTS: string = "company-documents";
export const EMPLOY_DOCUMENTS: string = "employ-documents";
export const FORM_SETUP: string = "form-setup";
export const FORM_BUILDER_TAB: string = "form-builder";

// form-builder
export const FORM_BUILDER_MODULE: string = SETTINGS + "/form-builder";
export const FORMS_TAB: string = "forms";

export const DEVICES: string = "devices";

// leave -management
export const LEAVE_MANAGEMENT: string = "/leaves";
export const LEAVE_APPLICATION: string = "employee-applications";
export const EMPLOYEE_LEAVE: string = "employee-balance";
export const LEAVE_TYPE_SETTING: string = "config";
export const OWN_APPLICATION: string = "my-applications";
export const PEER_APPLICATION: string = "peer-applications";
export const OWN_BALANCE: string = "my-balance";
export const APPROVAL: string = "approvals";

export const HOLIDAY: string = "/holiday";
export const HOLIDAYS: string = "holidays";
export const HOLIDAY_GROUP: string = "holiday-group";
// end
export const ASSETS_MANAGEMENT: string = "/asset-management";

export const ASSET_FORM: string = ASSETS_MANAGEMENT + ASSETS + "/form";
export const ASSET_DETAILS: string = ASSETS_MANAGEMENT + ASSETS + "/details";
export const ASSET_TYPES: string = "asset-types";

export const BANK_MANAGEMENT: string = SETTINGS + "/bank-management";
export const BANK: string = "bank";
export const BRANCH: string = "branch";
export const DIGITAL_BANKING: string = "digital-banking";

export const PROFILE: string = "profile";
export const PROFILE_GENERAL: string = "general";
export const PROFILE_PASSWORD: string = "password";

export const LEAVE_SETTINGS: string = SETTINGS + "/leaves";
export const APPROVAL_SETTINGS: string = SETTINGS + "/approval";

// export const ACCOUNT_SETTINGS: string = SETTINGS + "/account-setting";
export const NOTIFICATIONS: string = "/notifications";
export const ACL: string = SETTINGS + "/acl";

export const FACILITIES: string = SETTINGS + "/facilities";

//integrations
export const INTEGRATIONS: string = SETTINGS + "/integrations";
export const ATTENDANCES_DEV = INTEGRATIONS + "/attendances";
export const ATTENDANCE_DEVICES: string = "devices";
export const ATTENDANCE_LOCATIONS: string = "locations";

export const APIS: string = INTEGRATIONS + "/apis";
export const API_TOKENS: string = "api-tokens";

//Dashboard-payroll
export const DASHBOARD_PAYROLL: string = "/payroll";
export const EMPLOYEE_SALARIES: string = "employee-salary";
export const PAYROLL_HUB: string = "payroll-hub";

//settings-payroll

export const SETTINGS_PAYROLL: string = SETTINGS + "/payroll";
export const SALARY: string = SETTINGS_PAYROLL + "/salary";
export const SALARY_COMPONENTS: string = "components";
export const SALARY_STRUCTURE: string = "salary-structure";
