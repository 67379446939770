import NoDataFoundImg from "@assets/images/NoDataFound.png";
import Icon from "@components/Icon/Icon";
import { useTranslation } from "react-i18next";

const NoDataFound = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-NoDataFoundBg bg-no-repeat  lg:pt-0 md:pt-32 ">
      <div className=" bg-NoDataFoundBgRight bg-no-repeat bg-right-bottom ">
        <div className="  lg:px-[130px] lg:flex  justify-between  items-center lg:h-screen">
          <div className="lg:w-6/12 lg:px-0 md:px-[130px] xs:px-5 sm:px-20 xs:pt-6 sm:pt-20">
            <h1 className="font-Inter text-[36px] font-semibold lg:tracking-[5.76px] leading-[130%] text-neutral-800 xs:text-xl">
              {t("oops")}
            </h1>
            <h1 className="font-Inter text-[20px] font-semibold lg:tracking-[3.2px] leading-[130%] text-neutral-700 mt-[16px] xs:text-sm">
              {t("No Data Found")}
            </h1>
            <p className="font-Inter text-[20px] font-semibold  leading-[130%] text-neutral-400 my-[24px] lg:w-[482px] xs:text-sm">
              Lorem ipsum dolor sit amet consectetur. In gravida sit fermentum
              augue non. Aenean et interdum sit morbi massa vitae odio sed.
            </p>
            <button className="flex text-primary-500 items-center">
              <Icon name={"keyboard_arrow_left"} />
              <p className="font-Inter text-[16px] font-semibold  leading-[130%] xs:text-sm">
                {t("Go Back")}
              </p>
            </button>
          </div>
          <div className="lg:w-6/12  ">
            <img
              src={NoDataFoundImg}
              className="!mx-auto lg:mt-0 md:mt-10"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDataFound;
