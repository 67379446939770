import { lazy } from "react";
import { IRouteList } from "./list.route";
import { ORGANIZATION_CALENDER } from "./path-name.route";

export const organizationCalenderRoute: IRouteList[] = [
  {
    path: ORGANIZATION_CALENDER,
    component: lazy(() => import("@pages/OrganizationCalender/OrganizationCalender")),
  },
];
