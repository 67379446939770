import PasswordUpdate from "@assets/images/PasswordUpdate.png";
import Logo from "@assets/images/rysenova-logo-v7_720.png";
import Button from "@components/Button/Button";
import SuccessCard from "@components/Card/SuccessCard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PasswordUpdateSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className="">
        <div className="lg:pl-10  mt-10 ">
          <img src={Logo} alt="" className="w-auto h-10" />
        </div>
      </div>
      <div className="flex justify-center items-center w-full max-h-screen mt-[5%] xs:mt-32">
        <SuccessCard
          img={PasswordUpdate}
          status="Congratulation"
          message="Password Updated!"
        >
          <p className="font-inter text-base font-medium text-neutral-500">
            {t("Please login with new password")}
          </p>
          <Button
            className="px-6 py-3 mt-9 justify-center"
            width="96"
            onClick={() => navigate("/login")}
            color="primary"
            variant="fill"
            round="md"
          >
            {/* {t("Sign IN")} */}
            Login into your account
          </Button>
        </SuccessCard>
      </div>
    </>
  );
};

export default PasswordUpdateSuccess;
