import { hrmSettingsAcl } from "@acl/modules/setting.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
	ACL,
	ROLE,
	USERS,
	USER_DETAILS,
	USER_PERMISSION,
} from "./path-name.route";

const Acl = lazy(() => import("@pages/Settings/ACL/Acl"));

export const aclRoutes: IRouteList[] = [
	{
		path: ACL + "/" + USER_DETAILS,
		component: lazy(
			() => import("@pages/Settings/ACL/Screens/UserDetails/UserDetails")
		),
		meta: {
			action: hrmSettingsAcl.acl.user.getAll.action,
			resource: hrmSettingsAcl.acl.user.getAll.resource,
		},
	},
	{
		path: ACL + "/" + USER_PERMISSION + "/:userId",
		component: lazy(
			() =>
				import(
					"@pages/Settings/ACL/Screens/UserPermission/UserPermissionLayout"
				)
		),
		meta: {
			action: hrmSettingsAcl.acl.user.permissionAssignAndRevoke.action,
			resource:
				hrmSettingsAcl.acl.user.permissionAssignAndRevoke.resource,
		},
	},

	{
		path: ACL + "/" + ROLE + "/permission/:id",
		component: lazy(
			() =>
				import(
					"@pages/Settings/ACL/Screens/RolePermission/RolePermissionLayout"
				)
		),
		meta: {
			action: hrmSettingsAcl.acl.role.rolePermissionAssign.action,
			resource: hrmSettingsAcl.acl.role.rolePermissionAssign.resource,
		},
	},

	{
		path: ACL + "/*",
		component: Acl,
		meta: {
			action: hrmSettingsAcl.acl.parent.action,
			resource: hrmSettingsAcl.acl.parent.resource,
		},
		children: [
			{
				path: ROLE,
				component: lazy(
					() => import("@pages/Settings/ACL/Tabs/Roles/Roles")
				),
				meta: {
					action: hrmSettingsAcl.acl.role.getAll.action,
					resource: hrmSettingsAcl.acl.role.getAll.resource,
				},
			},
			{
				path: USERS,
				component: lazy(
					() => import("@pages/Settings/ACL/Tabs/Users/Users")
				),
				meta: {
					action: hrmSettingsAcl.acl.user.getAll.action,
					resource: hrmSettingsAcl.acl.user.getAll.resource,
				},
			},
			{
				path: USER_DETAILS,
				component: lazy(
					() =>
						import(
							"@pages/Settings/ACL/Screens/UserDetails/UserDetails"
						)
				),
				meta: {
					action: hrmSettingsAcl.acl.user.findOne.action,
					resource: hrmSettingsAcl.acl.user.findOne.resource,
				},
			},
		],
	},
];
