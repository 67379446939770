import { hrmSettingsAcl } from "@acl/modules/setting.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
	APPROVAL,
	LEAVE_SETTINGS,
	LEAVE_TYPES,
	LEAVE_TYPE_SETTING,
} from "./path-name.route";

export const leaveSettingsRoutes: IRouteList[] = [
	{
		path: LEAVE_SETTINGS + "/*",
		component: lazy(() => import("@pages/Settings/Leaves/Leave")),
		meta: {
			action: hrmSettingsAcl.leave.parent.action,
			resource: hrmSettingsAcl.leave.parent.resource,
		},
		children: [
			{
				path: LEAVE_TYPES,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Leaves/Tabs/LeaveType/LeaveType"
						)
				),
				meta: {
					action: hrmSettingsAcl.leave.leaveType.getAll.action,
					resource: hrmSettingsAcl.leave.leaveType.getAll.resource,
				},
			},
			{
				path: LEAVE_TYPE_SETTING,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Leaves/Tabs/LeaveSettings/LeaveSetting"
						)
				),
				meta: {
					action: hrmSettingsAcl.leave.settings.getAll.action,
					resource: hrmSettingsAcl.leave.settings.getAll.resource,
				},
			},
			{
				path: APPROVAL,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Approvals/Tabs/Approval/Approval"
						)
				),

				meta: {
					action: hrmSettingsAcl.leave.policy.getAll.action,
					resource: hrmSettingsAcl.leave.policy.getAll.resource,
				},
			},
		],
	},
];
