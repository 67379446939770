const NotificationSke = () => {
  return (
    <div className="w-full mt-4 space-y-7">
      {/* item */}
      <div className="flex  gap-6 w-full">
        <div className="skeleton rounded w-6 h-5" />
        <div className="flex gap-6 items-center w-full">
          <div className="w-full">
            <div className="skeleton rounded w-full h-4" />
            <div className="skeleton rounded w-[60%] mt-2 h-4" />
            <div className="skeleton rounded w-[25%] mt-3 h-3" />
          </div>
          <p className="skeleton w-4 rounded-full h-4" />
        </div>
      </div>
      {/* item */}
      <div className="flex  gap-6 w-full">
        <div className="skeleton rounded w-6 h-5" />
        <div className="flex gap-6 items-center w-full">
          <div className="w-full">
            <div className="skeleton rounded w-full h-4" />
            <div className="skeleton rounded w-[60%] mt-2 h-4" />
            <div className="skeleton rounded w-[25%] mt-3 h-3" />
          </div>
          <p className="skeleton w-4 rounded-full h-4" />
        </div>
      </div>
      {/* item */}
      <div className="flex  gap-6 w-full">
        <div className="skeleton rounded w-6 h-5" />
        <div className="flex gap-6 items-center w-full">
          <div className="w-full">
            <div className="skeleton rounded w-full h-4" />
            <div className="skeleton rounded w-[60%] mt-2 h-4" />
            <div className="skeleton rounded w-[25%] mt-3 h-3" />
          </div>
          <p className="skeleton w-4 rounded-full h-4" />
        </div>
      </div>
      {/* item */}
      <div className="flex  gap-6 w-full">
        <div className="skeleton rounded w-6 h-5" />
        <div className="flex gap-6 items-center w-full">
          <div className="w-full">
            <div className="skeleton rounded w-full h-4" />
            <div className="skeleton rounded w-[60%] mt-2 h-4" />
            <div className="skeleton rounded w-[25%] mt-3 h-3" />
          </div>
          <p className="skeleton w-4 rounded-full h-4" />
        </div>
      </div>
      {/* item */}
      <div className="flex  gap-6 w-full">
        <div className="skeleton rounded w-6 h-5" />
        <div className="flex gap-6 items-center w-full">
          <div className="w-full">
            <div className="skeleton rounded w-full h-4" />
            <div className="skeleton rounded w-[60%] mt-2 h-4" />
            <div className="skeleton rounded w-[25%] mt-3 h-3" />
          </div>
          <p className="skeleton w-4 rounded-full h-4" />
        </div>
      </div>
    </div>
  );
};

export default NotificationSke;
