import { lazy } from "react";
import { IRouteList } from "./list.route";

import { facilitiesSettingsAcl } from "@acl/modules/setting.data";
import { COMPANY_DOCUMENTS, FORMS_TAB, FORM_BUILDER_MODULE } from "./path-name.route";

export const formBuildersRoutes: IRouteList[] = [
  {
    path: FORM_BUILDER_MODULE + "/*",
    component: lazy(() => import("@pages/Settings/FormBuilder/FormBuilder")),
    meta: {
      action: facilitiesSettingsAcl.bankInfo.parent.action,
      resource: facilitiesSettingsAcl.bankInfo.parent.resource,
    },
    children: [
      {
        path: FORMS_TAB,
        component: lazy(() => import("@pages/Settings/FormBuilder/Tab/Forms/Forms")),
        meta: {
          action: facilitiesSettingsAcl.bankInfo.bank.getAll.action,
          resource: facilitiesSettingsAcl.bankInfo.bank.getAll.resource,
        },
      },
      {
        path: COMPANY_DOCUMENTS,
        component: lazy(() => import("@pages/Settings/FormBuilder/Tab/FormBuilderCmpnyDoc/FormBuilderCmpnyDoc")),
        meta: {
          action: facilitiesSettingsAcl.bankInfo.bank.getAll.action,
          resource: facilitiesSettingsAcl.bankInfo.bank.getAll.resource,
        },
      },
    ],
  },
];
