import { apiIns } from "@config/api.config";

export const GlobalService = {
  delete: async (modulePath: string, file_id: string): Promise<any> =>
    await apiIns.delete(modulePath + "/file/" + file_id),
  statusUpdate: async (
    modulePath: string,
    resource_id: string,
    newStatus: { status?: boolean | string; is_active?: boolean }
  ): Promise<any> =>
    await apiIns.put(modulePath + "/active-status/" + resource_id, newStatus),
};
