import { holidayAcl } from "@acl/modules/holiday.data";
import { facilitiesSettingsAcl } from "@acl/modules/setting.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
	ASSET_TYPES,
	FACILITIES,
	HOLIDAYS,
	HOLIDAY_GROUP,
} from "./path-name.route";

export const facilitiesRoutes: IRouteList[] = [
	{
		path: FACILITIES + "/*",
		component: lazy(() => import("@pages/Settings/Facilities/Facilities")),
		meta: {
			action: facilitiesSettingsAcl.facilities.parent.action,
			resource: facilitiesSettingsAcl.facilities.parent.resource,
		},
		children: [
			{
				path: HOLIDAYS,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Facilities/Tabs/Holidays/Holidays"
						)
				),
				meta: {
					action: holidayAcl.holiday.getAll.action,
					resource: holidayAcl.holiday.getAll.resource,
				},
			},

			{
				path: HOLIDAY_GROUP,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Facilities/Tabs/HolidayGroups/HolidayGroups"
						)
				),
				meta: {
					action: holidayAcl.group.getAll.action,
					resource: holidayAcl.group.getAll.resource,
				},
			},
			{
				path: ASSET_TYPES,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Facilities/Tabs/AssetType/AssetType"
						)
				),
				meta: {
					action: facilitiesSettingsAcl.facilities.assetType.getAll
						.action,
					resource:
						facilitiesSettingsAcl.facilities.assetType.getAll
							.resource,
				},
			},
		],
	},
];
