import { hrmSettingsAcl } from "@acl/modules/setting.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import { APPROVAL, APPROVAL_SETTINGS } from "./path-name.route";

export const approvalSettingsRoutes: IRouteList[] = [
	{
		path: APPROVAL_SETTINGS + "/*",
		component: lazy(() => import("@pages/Settings/Approvals/Approvals")),
		meta: {
			action: hrmSettingsAcl.approval.parent.action,
			resource: hrmSettingsAcl.approval.parent.resource,
		},
		children: [
			{
				path: APPROVAL,
				component: lazy(
					() =>
						import(
							"@pages/Settings/Approvals/Tabs/Approval/Approval"
						)
				),

				meta: {
					action: hrmSettingsAcl.approval.approvals.getAll.action,
					resource: hrmSettingsAcl.approval.approvals.getAll.resource,
				},
			},
		],
	},
];
