import { default as blob1 } from "@assets/images/organization-notfound-blob-1.png";
import { default as blob2 } from "@assets/images/organization-notfound-blob-2.png";
import { default as img } from "@assets/images/organization-notfound-img.png";
import Logo from "@assets/images/rysenova-logo-v7_720.png";
import { Link } from "react-router-dom";

const OrganizationNotFound = () => {
	return (
		<div className="w-full lg:py-24 lg:px-20 md:py-14 md:px-14 sm:py-8 sm:px-8 xs:py-6 xs:px-6 h-auto lg:h-[100vh] sm:h-auto md:h-[100vh] relative">
			<img
				className="absolute -z-[99] top-2 lg:top-10 left-0 mb-20"
				src={blob1}
				alt=""
			/>
			<img
				className="absolute bottom-0 right-0 -z-[99]"
				src={blob2}
				alt=""
			/>

			<div className="w-full">
				<div className="mb-20">
					<img
						src={Logo}
						alt=""
						className="lg:w-[273px] md:w-[240px] lg:h-10 h-auto sm:w-[220px] xs:w-[200px]"
					/>
				</div>
				<div className="lg:mt-36 md:mt-32 sm:mt-6 xs:mt-6 flex flex-col lg:flex-row md:flex-row w-full h-full lg:gap-10  lg:items-center lg:justify-between">
					{/* left */}
					<div className="lg:pl-10 xs:pl-6 basis-1/2">
						<h2 className="text-neutral-800 uppercase tracking-[5.76px] md:text-xl sm:text-xl lg:text-6xl font-semibold mb-4 ">
							ORGANIZATION{" "}
							<span className="block">NOT FOUND !</span>
						</h2>
						<h2 className="text-neutral-700 text-sm lg:text-xl tracking-[3.2px] uppercase font-semibold ">
							The URL you entered:
						</h2>
						<h2 className="text-neutral-700 mb-3 lg:mb-5 tracking-[3.2px] text-sm lg:text-xl font-semibold">
							{`${
								window.location.hostname.split(".")[0]
							}.rysenova.app`}
						</h2>

						<h2 className="text-neutral-700 text-sm lg:text-xl  tracking-[3.2px] uppercase font-semibold ">
							Transform Your HR Experience Today
						</h2>

						<div className="mt-8">
							<Link
								className=" py-3 px-6 lg:px-6 rounded-md font-Inter text-white text-xs lg:text-base font-medium bg-primary-900"
								to="https://rysenova.com/sign-up"
							>
								Sign up for free
							</Link>
						</div>
						<div className="mt-8">
							<Link
								className=" font-Inter text-sm lg:text-base font-semibold text-primary-500"
								to="https://rysenova.com/"
							>
								Browse Website
							</Link>
						</div>
					</div>
					<div className=" w-full basis-1/2">
						<img
							className="lg:w-[523px] xs:w-[390px] ml-auto "
							src={img}
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrganizationNotFound;
