const SidebarSkeleton = ({ show }: { show: boolean }) => {
  return (
    <div className={`px-5 py-6 bg-white ${show ? "" : "hidden"}`}>
      <div>
        <div className="skeleton w-16 h-4 rounded" />
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-16 h-4 rounded bg-slate-400 bg-opacity-30" />
        </div>
      </div>
      {/*  */}
      <div className="mt-7">
        <div className="skeleton w-20 h-4 rounded" />
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-28 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-16 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-24 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
      </div>

      {/*  */}
      <div className="mt-7">
        <div className="skeleton w-14 h-4 rounded" />
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-28 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-16 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
      </div>
      {/*  */}
      <div className="mt-7">
        <div className="skeleton w-20 h-4 rounded" />
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-28 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-16 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-24 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="mt-7">
        <div className="skeleton w-28 h-4 rounded" />
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-28 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-16 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="mt-7">
        <div className="skeleton w-20 h-4 rounded" />
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-28 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-16 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="mt-7">
        <div className="skeleton w-20 h-4 rounded" />
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-28 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
        <div className="skeleton w-full h-8 rounded flex items-center gap-3 mt-3 px-3">
          <div className="skeleton w-4 h-4 rounded bg-slate-400 bg-opacity-30" />
          <div className="skeleton w-16 h-3 rounded bg-slate-400 bg-opacity-30" />
        </div>
      </div>
      {/*  */}
    </div>
  );
};

export default SidebarSkeleton;
