import Logo from "@assets/images/rysenova-logo-v7_720.png";

import { AuthService } from "@services/apis/Auth.service";
import { useSkeleton } from "@services/utils/skeleton.service";
import { ToastService } from "@services/utils/toastr.service";
import { createContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UpdateEmailVerify from "./EmailVerification/Components/UpdateEmailVerify";
import VerificationLinkBroken from "./EmailVerification/Components/VerificationLinkBroken";

interface IUserData {
  expire_at: string;
  token: string;
  user_name: string;
  status: string;
}
interface IEmailVerify {
  token: string | any;
  userData: IUserData | any;
  handleRegenerateEmailLink: Function;
  handleUpdateEmailVerify: Function;
  startSkeleton: boolean;
  type: string | any;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

export const updateEmailVerifyContext = createContext({} as IEmailVerify);

const UpdateEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const type = searchParams.get("type");

  const [linkBroken, setLinkBroken] = useState<boolean>(false);

  const [userData, setUserData] = useState<IUserData>();
  const { startSkeleton, stopSkeleton } = useSkeleton();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      accountVerification();
    }
  }, [token]);

  const accountVerification = () => {
    if (!token) return;
    setIsLoading(true);
    AuthService.updateEmailVerify(token, type)
      .then((res) => {
        setLinkBroken(false);
        setUserData(res.payload);
      })
      .catch((err) => {
        ToastService.error(err.message);
        setLinkBroken(true);
      })
      .finally(() => {
        stopSkeleton();
        setIsLoading(false);
      });
  };

  const handleRegenerateEmailLink = () => {
    if (!token) return;
    setIsLoading(true);
    AuthService.regenerateUpdateEmailVerifyLink(token, type)
      .then((res) => {
        setLinkBroken(false);
        setUserData(res.payload);
      })
      .catch((err) => {
        ToastService.error(err.message);
        setLinkBroken(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateEmailVerify: any = () => {
    if (!token) return;
    setIsLoading(true);
    AuthService.verifyUpdateEmail(token, type)
      .then(() => {
        navigate("/success-update-email");
      })
      .catch((error) => {
        ToastService.error(error.message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <updateEmailVerifyContext.Provider
      value={{
        token,
        userData,
        handleRegenerateEmailLink,

        startSkeleton,
        type,

        isLoading,
        setIsLoading,
        handleUpdateEmailVerify,
      }}
    >
      <div className="relative bg-cover bg-center">
        <div className="h-[100vh] overflow-hidden">
          <div className="lg:pl-10  mt-10 ">
            <img src={Logo} alt="" className="w-auto h-10" />
          </div>
          {linkBroken ? <VerificationLinkBroken contextType={"verifyUpdateEmail"} /> : <UpdateEmailVerify />}
        </div>

        <div
          className={`absolute top-10 left-10 -z-[1]  shadow-2xl `}
          style={{
            backgroundColor: "rgba(56, 153, 244, 0.22)",
            height: "318px",
            width: "320px",
            borderRadius: "400px",
            boxShadow: "0px 0px 0px 0px rgba(151, 71, 255, 0.09)",
            filter: "blur(75px)",
          }}
        />
        <div
          className={`absolute right-0 -bottom-10 -z-[1]  shadow-2xl  `}
          style={{
            backgroundColor: "rgba(56, 153, 244, 0.22)",
            height: "318px",
            width: "320px",
            borderRadius: "400px",
            boxShadow: "0px 0px 0px 0px rgba(56, 153, 244, 0.22)",
            filter: "blur(75px)",
          }}
        />
      </div>
    </updateEmailVerifyContext.Provider>
  );
};

export default UpdateEmail;
