import { IAclCommonField, IActionResource } from "@acl/acl.interfaces";
import { commonFieldAcl } from ".";

export type IAssetACL = IAclCommonField & {
  assetActivities: IActionResource;
  getAssetActivities: IActionResource;
};

export const assetAcl: IAssetACL = {
  ...commonFieldAcl("assets"),
  assetActivities: { action: "assetActivities", resource: "assets" },
  getAssetActivities: { action: "getAssetActivities", resource: "assets" },
};
