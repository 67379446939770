import { attendanceAcl } from "@acl/modules/attendance.data";
import { defaultEmployeeAcl, lineManagerAcl } from "@acl/modules/employee.data";
import { lazy } from "react";
import { IRouteList } from "./list.route";
import {
  ATTENDANCE,
  ATTENDANCE_CHECK_IN,
  ATTENDANCE_LIST,
  ATTENDANCE_RECORD,
  ATTENDANCE_SUMMARY,
  PENDING_REQUEST,
} from "./path-name.route";

export const attendanceRoutes: IRouteList[] = [
  {
    path: ATTENDANCE + "/*",
    component: lazy(() => import("@pages/Attendance/Attendance")),
    meta: {
      action: attendanceAcl.parent.action,
      resource: attendanceAcl.parent.resource,
    },
    children: [
      {
        path: ATTENDANCE_RECORD,
        component: lazy(
          () => import("@pages/Attendance/AttendanceRecord/AttendanceRecord")
        ),
        meta: {
          action: attendanceAcl.record.getAll.action,
          resource: attendanceAcl.record.getAll.resource,
        },
      },
      {
        path: PENDING_REQUEST,
        component: lazy(
          () => import("@pages/Attendance/PendingRequest/PendingRequest")
        ),
        meta: [
          {
            action: lineManagerAcl.action,
            resource: lineManagerAcl.resource,
          },
          {
            action: attendanceAcl.attendanceRequest.getAll.action,
            resource: attendanceAcl.attendanceRequest.getAll.resource,
          },
        ],
      },
      {
        path: ATTENDANCE_LIST,
        component: lazy(
          () => import("@pages/Attendance/AttendanceSummery/AttendanceSummery")
        ),
        meta: {
          action: attendanceAcl.summary.action,
          resource: attendanceAcl.summary.resource,
        },
      },
      {
        path: ATTENDANCE_CHECK_IN,
        component: lazy(
          () => import("@pages/Attendance/CheckIn/AttendanceCheckIn")
        ),
        meta: {
          action: defaultEmployeeAcl.action,
          resource: defaultEmployeeAcl.resource,
        },
      },
      {
        path: ATTENDANCE_SUMMARY,
        component: lazy(() => import("@pages/Attendance/Summery/Summary")),
        meta: {
          action: defaultEmployeeAcl.action,
          resource: defaultEmployeeAcl.resource,
        },
      },
    ],
  },
];
