import { TopProgressCom } from "@services/utils/topProgress.service";
import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BASIC_INFO, ONBOARD, STEP } from "./path-name.route";

const OnboardLayout = lazy(() => import("@layouts/OnboardLayout"));
const InitialOnboarding = lazy(
  () => import("@pages/Onboard/InitialOnboarding/InitialOnboarding")
);
const OnboardingStep = lazy(
  () => import("@pages/Onboard/OnboardingStep/OnboardingStep")
);
const OnboardDepartment = lazy(
  () => import("@pages/Onboard/OnboardingStep/Step/OnboardDepartment")
);
const OnboardDesignation = lazy(
  () => import("@pages/Onboard/OnboardingStep/Step/OnboardDesignation")
);
const OnboardDivision = lazy(
  () => import("@pages/Onboard/OnboardingStep/Step/OnboardDivision")
);
const OnboardGrade = lazy(
  () => import("@pages/Onboard/OnboardingStep/Step/OnboardGrade")
);
const OnboardSession = lazy(
  () => import("@pages/Onboard/OnboardingStep/Step/OnboardSession")
);

const OnboardRoutes = () => {
  return (
    <Suspense fallback={<TopProgressCom />}>
      <Routes>
        <Route element={<OnboardLayout />}>
          <Route path={BASIC_INFO} element={<InitialOnboarding />} />
          <Route path={STEP + "/*"} element={<OnboardingStep />}>
            <Route
              path={"*"}
              index
              element={<Navigate to="division" replace />}
            />
            <Route path={"division"} element={<OnboardDivision />} />
            <Route path={"department"} element={<OnboardDepartment />} />
            <Route path={"grade"} element={<OnboardGrade />} />
            <Route path={"designation"} element={<OnboardDesignation />} />
            <Route path={"session"} element={<OnboardSession />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to={ONBOARD + BASIC_INFO} />} />
      </Routes>
    </Suspense>
  );
};

export default OnboardRoutes;
